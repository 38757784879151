import React, { useEffect, useRef, useState } from 'react';
import { Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { MdOutlineArrowBack } from 'react-icons/md';
import { TbReportSearch } from 'react-icons/tb';
import { IoMdAnalytics } from 'react-icons/io';
import '../styles/components.scss';

const SidePanel = ({ openSidePanel, setOpenSidePanel, tabHandler, activeTab }) => 
{
    const [isFeatureDataSubMenuOpen, setIsFeatureDataSubMenuOpen] = useState(false);
    const [isMonitorAnalyticsSubMenuOpen, setIsMonitorAnalyticsSubMenuOpen] = useState(false);
    const sidePanelRef = useRef();
    useOutsideAlerter(sidePanelRef);

    function useOutsideAlerter(ref) 
    {
        useEffect(() => 
        {
            function handleClickOutside(event) 
            {
                if (ref?.current && !ref?.current?.contains(event.target)) 
                {
                    setOpenSidePanel(false);
                }
            }

            document.addEventListener("mousedown", handleClickOutside);

            return () => 
            {
                document.removeEventListener("mousedown", handleClickOutside);
            };

        }, [ref]);
    }

    const handleFeatureDataSubMenuState = (state) => 
    {
        setIsFeatureDataSubMenuOpen(state);
    }

    const handleMonitorAnalyticsSubMenuState = (state) => 
    {
        setIsMonitorAnalyticsSubMenuOpen(state);
    }

    return (
        <div className = {`sidePanel ${openSidePanel && 'openSidePanel'}`} ref = {sidePanelRef}>
            <div className = "backButton">
                <a href = "#/" onClick = {(event) => { event.preventDefault(); setOpenSidePanel(false); }}>
                    <MdOutlineArrowBack /> Back
                </a>
            </div>

            <div className = "d-flex flex-column justify-content-between" style = {{ height: '65%' }}>
                <div className = "nav_menu">
                    <Menu style = {{ marginTop: '40px' }}>
                        <SubMenu 
                            icon = {<TbReportSearch />} 
                            label = "Feature Data" 
                            open = {window.location.pathname === "/" || window.location.pathname.includes("feature-data") ? true : isFeatureDataSubMenuOpen}
                            onOpenChange = {(state) => handleFeatureDataSubMenuState(state)}
                        >
                            <MenuItem active = {activeTab === '/' || activeTab === '/feature-data/clorox-data'} onClick = {() => tabHandler('/feature-data/clorox-data', 'mobile_view')}>
                                Clorox Data
                            </MenuItem>
                            <MenuItem active = {activeTab === '/feature-data/clorox-and-competition-data'} onClick = {() => tabHandler('/feature-data/clorox-and-competition-data', 'mobile_view')}>
                                Clorox & Competition Data
                            </MenuItem>
                            <MenuItem active = {activeTab === '/feature-data/table-view'} onClick = {() => tabHandler('/feature-data/table-view', 'mobile_view')}>
                                Table View
                            </MenuItem>
                        </SubMenu>

                        <SubMenu 
                            icon = {<IoMdAnalytics />} 
                            label = "Monitor Analytics" 
                            open = {window.location.pathname.includes("monitor") ? true : isMonitorAnalyticsSubMenuOpen}
                            onOpenChange = {(state) => handleMonitorAnalyticsSubMenuState(state)}
                        >
                            <MenuItem active = {window.location.pathname === '/monitor-analytics/monitor-performance'} onClick = {() => tabHandler('/monitor-analytics/monitor-performance', 'mobile_view')}>
                                Monitor Performance
                            </MenuItem>
                            <MenuItem active = {window.location.pathname === '/monitor-analytics/monitor-route'} onClick = {() => tabHandler('/monitor-analytics/monitor-route', 'mobile_view')}>
                                Monitor Route
                            </MenuItem>
                            <MenuItem active = {window.location.pathname === '/monitor-analytics/target-vs-actual'} onClick = {() => tabHandler('/monitor-analytics/target-vs-actual', 'mobile_view')}>
                                Target Vs Actual
                            </MenuItem>
                            <MenuItem active = {window.location.pathname === '/monitor-analytics/target-vs-actual-per-day'} onClick = {() => tabHandler('/monitor-analytics/target-vs-actual-per-day','mobile_view')}>
                                Target Vs Actual Per Day
                            </MenuItem>
                            <MenuItem active = {window.location.pathname === '/monitor-analytics/templates'} onClick = {() => tabHandler('/monitor-analytics/templates','mobile_view')}>
                                Templates
                            </MenuItem>
                            <MenuItem active = {window.location.pathname === '/monitor-analytics/availability'} onClick = {() => tabHandler('/monitor-analytics/availability','mobile_view')}>
                                OnShelf Availability
                            </MenuItem>
                            <MenuItem active = {window.location.pathname === '/monitor-analytics/share-of-shelf'} onClick = {() => tabHandler('/monitor-analytics/share-of-shelf','mobile_view')}>
                                Share Of Shelf
                            </MenuItem>
                            <MenuItem active = {window.location.pathname === '/monitor-analytics/planogram'} onClick = {() => tabHandler('/monitor-analytics/planogram','mobile_view')}>
                                Planogram
                            </MenuItem>
                            <MenuItem active = {window.location.pathname === '/monitor-analytics/secondary-display'} onClick = {() => tabHandler('/monitor-analytics/secondary-display','mobile_view')}>
                                Secondary Display
                            </MenuItem>
                            <MenuItem active = {window.location.pathname === '/monitor-analytics/summary'} onClick = {() => tabHandler('/monitor-analytics/summary','mobile_view')}>
                                Summary
                            </MenuItem>
                            <MenuItem active = {window.location.pathname === '/monitor-analytics/RSP'} onClick = {() => tabHandler('/monitor-analytics/RSP','mobile_view')}>
                                RSP
                            </MenuItem>
                        </SubMenu>
                    </Menu>
                </div>
            </div>
        </div>
    );
}

export default SidePanel;