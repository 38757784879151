import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  ChartDataLabels,
  Legend
);

const DoubleBarChart = ({ label,loading,dataset }) => {
  const [labels, setLabels] = useState(false);
  const [chartData, setChartData] = useState(false);
  const [title, setTitle] = useState(false);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        display: true,
        color: "#666666",
        align: "end",
        anchor: "end",
        font: { size: "12" }
      },
      title: {
        display: true,
        text: title ? title : "",
        color: '#065886',
        padding: {
          top: 10,
          bottom: 20
        },
        font: { size: "13",weight:'bolder' }
      },
    }
  };

  useEffect(() => {
    if (dataset) {
      setLabels(Object.keys(dataset));
      setChartData(Object.values(dataset));
      setTitle(label?label:'');
    }
  }, [dataset,label]);

  const data = {
    labels,
    datasets: [
      {
        data: chartData,
        backgroundColor: '#128CFE'
      }
    ]
  };

  return (
    <div>
      {/* {loading ? (
        <div className="col-12 text-center">
          <br />
          <div className="spinner-border text-info" role="status" style={{ zIndex: '2' }}>
          </div>
          <span className="text-info d-block">{title}</span>
        </div>
      ) : ( */}
        <Bar options={options} plugins={[ChartDataLabels]} data={data} height={200} width={300} />
      {/* ) */}
      {/* } */}
    </div>
  );
}

export default DoubleBarChart;