import React, { useState } from 'react';
import { Button, Alert } from 'react-bootstrap';
import { Navigate, Link } from 'react-router-dom';
import axios from 'axios';
import Logo from '../../assets/clorox.png';
import 'semantic-ui-css/semantic.min.css';
import '../../styles/login.css';

const ResetPassword = ({ currentUser }) => 
{
  const [isLoading, setIsLoading] = useState(false);
  const [okAlert, setOkAlert] = useState(null);
  const [alertError, setAlertError] = useState(null)
  const [email, setEmail] = useState(null);

  const handleChange = (event) => 
  {
    if (typeof event.target.value !== "undefined" && event.target.value !== "") 
    {
      var pattern = new RegExp(
        /^(('[\w-\s]+')|([\w-]+(?:\.[\w-]+)*)|('[\w-\s]+')([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );

      if (!pattern.test(event.target.value)) 
      {
        event.target.setCustomValidity("Please enter a valid email address.");
      }
      else 
      {
        event.target.setCustomValidity("");
      }

      setEmail(event.target.value);
    }
  }

  const handleSubmit = async (event) => 
  {
    event.preventDefault();
  
    setIsLoading(true);
    setAlertError(null);
    setOkAlert("");
    
    await axios({
      method: "post",
      url: "/api/forgot_pass",
      headers: { 'Content-Type': 'application/json' },
      data: { Email: email }
    })
    .then((response) => 
    {
      setIsLoading(false);

      if (response.status === 200) 
      {
        setOkAlert("Your password has been reset and sent via email. Please check your inbox.");
      } 
      else if (response.status === 202) 
      {
        setAlertError("Email Not Registered!");
      } 
      else 
      {
        setAlertError("Password Change Unsuccessful Due To Server Error!");
      }
    })
    .catch((error) => 
    {
      console.log("Forgot Password Api: ", error);
      setIsLoading(false);
      setAlertError("Something went wrong. Please try again.");
    });
  }

  return (
    <>
      {currentUser?.user ? (
        <Navigate to = "/" replace = {true} />
      ) : (
        <div className = "loginContainer">
          <div className = "row">
            <div className = "col-md-7">
              <div className = "login">
                <img src = {Logo} alt = "Clorox" />
                <br />

                <h1>Reset Your Password</h1>
                <div className = "sub-title">
                  Enter your email address below to change your password.
                </div>

                {isLoading && (
                  <div className = "ui active dimmer Loader">
                    <div className = "ui loader"></div>
                  </div>
                )}

                {okAlert ? (
                  <Alert variant = "success">{okAlert}</Alert>
                ) : alertError && (
                  <Alert variant = "danger">{alertError}</Alert>
                )}

                <form onSubmit = {handleSubmit}>
                  <div className = "form-group">
                    <input type = "email" name = "email" className = "form-control" id = "email" placeholder = "Email" onChange = {handleChange} required />
                  </div>

                  <div className = "d-flex justify-content-between align-items-center reset-password">
                    <Link to = "/login">Back To Login</Link>
                    <Button type = "submit" variant = "primary" className = "login-button">Reset Password</Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ResetPassword;