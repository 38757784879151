import React from 'react';
import SingleBarChart from '../../components/SingleBarChart';
import MultiTuple from '../../components/MultiTuple';
import LineChart from '../../components/LineChart';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { MdOutlineAccessTimeFilled } from 'react-icons/md';
import '../../styles/monitorAnalytics.scss';
import { BiStore } from 'react-icons/bi';
import DynamicDataTable from '../../components/DynamicDataTable';

const ChartView = (props) => {
    return (
        <div className="container-fluid monitor_performance position-relative">
            <div className="row align-items-center justify-content-center statistics">
                <div className="col-8 col-md-3 media-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                <div className="d-flex justify-content-between">
                                    <div className="align-self-center d_sm_none">
                                        <FaMapMarkerAlt className="icon" />
                                    </div>
                                    <div className="media-body text-right total_visits_con">
                                        <span>Total Visits</span>
                                        <h3 style={{ marginTop: '10px' }}>{props?.totalVisits ? props?.totalVisits : 0}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-8 col-md-3 media-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                <div className="d-flex justify-content-between">
                                    <div className="align-self-center d_sm_none">
                                        <MdOutlineAccessTimeFilled className="icon" />
                                    </div>
                                    <div className="media-body text-right total_visits_con">
                                        <span>Average Time / Visit</span>
                                        <h3 style={{ marginTop: '10px' }}>{props?.averageTimePerVisit ? props?.averageTimePerVisit : 0}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-8 col-md-3 media-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                <div className="d-flex justify-content-between">
                                    <div className="align-self-center d_sm_none">
                                        <BiStore className="icon" />
                                    </div>
                                    <div className="media-body text-right total_visits_con">
                                        <span>Total Stores</span>
                                        <h3 style={{ marginTop: '10px' }}>{props?.totalStores ? props?.totalStores : 0}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {props?.tab && props?.tab !== "All Monitors" && (
                    <div className="col-md-3 media-body">
                        <div className="card">
                            <div className="card-content">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between">
                                        <div className="align-self-center">
                                            <MdOutlineAccessTimeFilled className="icon" />
                                        </div>
                                        <div className="media-body text-right">
                                            <span>Total Audit Time/Visit</span>
                                            <h3 style={{ marginTop: '10px' }}>{props?.totalAuditTimePerVisit ? props?.totalAuditTimePerVisit : 0}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}


                {/* <div className = "col-md-12 indicator-container">
                    <div className = "round_indicators">
                        <div className = "round_indicator">
                            <div className = "value">{props?.totalVisits ? props?.totalVisits : 0}</div>
                            <span className = "heading">Total Visits</span>
                        </div>

                        <div className = "round_indicator">
                            <div className = "value">{props?.averageTimePerVisit ? props?.averageTimePerVisit : 0}</div>
                            <span className = "heading">Avg Time/Visit</span>
                        </div>

                        {props?.tab && props?.tab !== "All Monitors" && (
                            <div className = "round_indicator">
                                <div className = "value">{props?.totalAuditTimePerVisit ? props?.totalAuditTimePerVisit : 0}</div>
                                <span className = "heading">Total Audit Time/Visit</span>
                            </div>
                        )}
                    </div>
                </div> */}
            </div>

            <div className="w-100">
                <br />
            </div>

            <div className="row align-items-center justify-content-between charts-container">
                <div className={`col-md-6 column-1 ${props?.isNumberOfStoresDataAvailable ? '' : 'no-data-container'}`}>
                    {props?.isNumberOfStoresDataAvailable ? (
                        <SingleBarChart
                            loading={props?.isNumberOfStoresLoading}
                            title={(props?.monitors && props?.monitors.length > 0) ? "Number of Stores by Users" : (props?.numberOfStoresDates && props?.numberOfStoresDates.length > 0) ? "Number of Stores by Date" : ""}
                            labels={(props?.monitors && props?.monitors.length > 0) ? props?.monitors : (props?.numberOfStoresDates && props?.numberOfStoresDates.length > 0) ? props?.numberOfStoresDates : []}
                            datasets={props?.numberOfStores}
                        />
                    ) : (
                        <div className="no-data">
                            <span>Number of Stores</span>
                            <span><small>No data found.</small></span>
                        </div>
                    )}
                </div>

                <div className={`col-md-6 column-2 ${props?.isShareOfShelfByMonthDataAvailable ? '' : 'no-data-container'}`}>
                    {props?.isShareOfShelfByMonthDataAvailable ? (
                        <MultiTuple loading={props?.isShareOfShelfByMonthLoading} title="SOS by Month" labels={props?.months} datasets={props?.shareOfShelfValuesByMonth} />
                    ) : (
                        <div className="no-data">
                            <span>SOS by Month</span>
                            <span><small>No data found.</small></span>
                        </div>
                    )}
                </div>
            </div>

            <div className="w-100">
                <br />
            </div>

            <div className="row align-items-center justify-content-between charts-container">
                <div className={`col-md-6 column-1 ${props?.isSecondaryDisplayByCategoryDataAvailable ? '' : 'no-data-container'}`}>
                    {props?.isSecondaryDisplayByCategoryDataAvailable ? (
                        <MultiTuple loading={props?.isSecondaryDisplayByCategoryLoading} title="Secondary Display by Category" labels={props?.categories} datasets={props?.secondaryDisplayValuesByCategory} />
                    ) : (
                        <div className="no-data">
                            <span>Secondary Display by Category</span>
                            <span><small>No data found.</small></span>
                        </div>
                    )}
                </div>

                <div className={`col-md-6 column-2 ${props?.isSecondaryDisplayByDateDataAvailable ? '' : 'no-data-container'}`}>
                    {props?.isSecondaryDisplayByDateDataAvailable ? (
                        <LineChart loading={props?.isSecondaryDisplayByDateLoading} title="Secondary Display by Date" labels={props?.secondaryDisplayDates} datasets={props?.secondaryDisplayValuesByDate} />
                    ) : (
                        <div className="no-data">
                            <span>Secondary Display by Date</span>
                            <span><small>No data found.</small></span>
                        </div>
                    )}
                </div>
            </div>

            <div className="w-100">
                <br />
            </div>

            <div className="row align-items-center justify-content-between charts-container store-chart-container">
                <div className={`col-md-6 column-1 ${props?.isSecondaryDisplayByStoreDataAvailable ? '' : 'no-data-container'}`}>
                    {props?.isSecondaryDisplayByStoreDataAvailable ? (
                        <MultiTuple loading={props?.isSecondaryDisplayByStoreLoading} title="Secondary Display by Store" labels={props?.stores} datasets={props?.secondaryDisplayValuesByStore} />
                    ) : (
                        <div className="no-data">
                            <span>Secondary Display by Store</span>
                            <span><small>No data found.</small></span>
                        </div>
                    )}
                </div>


                <div className="col-md-6 charts-container visits_store_table">

                    {(props?.rows?.length > 0) ?
                        (
                            <>
                                <label htmlFor="" className='table_label text-center my-0'>Visit Wave</label>
                                <DynamicDataTable columnDetails={props?.columnDetails}
                                    rowID={props?.rowID}
                                    columnHeaders={props?.columnHeaders}
                                    rows={props?.rows}
                                    isLoading={props?.isLoading}
                                    setPageSize={props?.setPageSize}
                                    pageSize={props?.pageSize}
                                    setPage={props?.setPage}
                                    page={props?.page}
                                    rowCount={props?.rowCount}
                                />
                            </>) : (
                            <>
                                <div className='table_no_data'>
                                    <div className="no-data">
                                        <span>Visit Wave</span>
                                        <span><small>No data found.</small></span>
                                    </div>
                                </div>

                            </>

                        )
                    }
                </div>

                {props?.tab && props?.tab !== "All Monitors" && (
                    <div className={`col-md-6 column-2 ${props?.isTotalActualTimeDataAvailable ? '' : 'no-data-container'}`}>
                        {props?.isTotalActualTimeDataAvailable ? (
                            <MultiTuple loading={props?.isTotalActualTimeLoading} title="Total Actual Time by Date" labels={props?.totalActualTimeDates} datasets={props?.totalActualTimeByDate} />
                        ) : (
                            <div className="no-data">
                                <span>Total Actual Time by Date</span>
                                <span><small>No data found.</small></span>
                            </div>
                        )}
                    </div>

                )}
            </div>
        </div>
    );
}

export default ChartView;