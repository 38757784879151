import React, { useState, useEffect } from 'react';
import { setRole } from '../../actions/userRole';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation, Navigate, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Alert, Button } from 'react-bootstrap';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import axios from 'axios';
import Logo from '../../assets/clorox.png';
import 'semantic-ui-css/semantic.min.css';
import '../../styles/login.css';

const Login = ({ currentUser, setCurrentUser }) => 
{
    const [isLoading, setIsLoading] = useState(false);
    const [alertError, setAlertError] = useState(null);
    const [isLoggedOut, setIsLoggedOut] = useState(localStorage.getItem("IsLoggedOut"));
    const [showPassword, setShowPassword] = useState(false);
    const { state } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const initUnLoad = (isLoggedOut) => 
    {
        window.onunload = () => 
        {
            if (isLoggedOut) 
            {
                localStorage.removeItem("IsLoggedOut");
                setIsLoggedOut(false);
            }
        };
    }

    window.onload = function() 
    {
        initUnLoad(isLoggedOut);
    };
    
    useEffect(() =>
    {
        initUnLoad(isLoggedOut);

    }, [isLoggedOut]);

    const togglePassword = () => 
    {
        setShowPassword(!showPassword);
    }

    const handleSubmit = async (event) => 
    {
        event.preventDefault();

        setIsLoading(true);
        setAlertError(null);
        setIsLoggedOut(false);

        const username = event.target.elements[0].value;
        const password = event.target.elements[1].value;

        await axios({
            method: "post",
            url: "/api/authenticate_user",
            headers: { 'Content-Type': 'application/json' },
            data: { username: username, password: password }
        })
        .then((response) => 
        {
            setIsLoading(false);

            if (response.status === 200)
            {
                const responseData = response.data;

                if (responseData.login)
                {
                    dispatch(setRole(username, "Administrator", null));
                    setCurrentUser({ user: username, role: "Administrator" });
                    navigate(state?.path || "/");
                }
                else
                {
                    setAlertError(responseData.data);
                }
            }
        })
        .catch((error) => 
        {
            console.log("Login Api: ", error);
            setIsLoading(false);
            setAlertError("Something went wrong. Please try again.");
        });
    }

    return (
        <>
            {currentUser?.user ? (
                <Navigate to = {state?.path || "/"} replace = {true} />
            ) : (
                <div className = "loginContainer">
                    <div className = "row">
                        <div className = "col-md-7">
                            <div className = "login">
                                <img src = {Logo} alt = "Clorox" />
                                <br />
                                
                                {isLoggedOut && (
                                    <Alert variant = "success">Logged Out Successfully.</Alert>
                                )}
                                
                                <h1>Login to Your Account</h1>
                                <br />

                                {isLoading && (
                                    <div className = "ui active dimmer Loader">
                                        <div className = "ui loader"></div>
                                    </div>
                                )}

                                {alertError && (
                                    <Alert variant = "danger">{alertError}</Alert>
                                )}

                                <form onSubmit = {handleSubmit}>
                                    <div className = "form-group">
                                        <input type = "text" name = "username" className = "form-control" id = "username" placeholder = "Username" required/>
                                    </div>
                                    <div className = "form-group password-container">
                                        <input type = {showPassword ? 'text' : 'password'} name = "password" className = "form-control" id = "password" placeholder = "Password" required/>
                                        {showPassword ? <AiOutlineEyeInvisible className = "show-hide-password" onClick = {togglePassword} /> : <AiOutlineEye className = "show-hide-password" onClick = {togglePassword} />}
                                    </div>

                                    <div className = "d-flex justify-content-between align-items-center reset-password">
                                        <span></span>
                                        <Link to = "/login/reset-password">Reset Password</Link>
                                    </div>
                                    
                                    <br />
                                    <Button type = "submit" variant = "primary" className = "login-button log-in">Log In</Button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

const mapStateToProps = state => 
{
  return {
    userRole: state.userRole
  };
};

export default connect(mapStateToProps)(Login);