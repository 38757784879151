import React, { useState, useEffect } from "react";
import DoubleBarChart from "../../components/DoubleBarChart";
import { Alert, Dropdown } from "react-bootstrap";
import axios from "axios";
import { BiReset } from "react-icons/bi";
import LineChart from "../../components/LineChart";
import "../../styles/featureData.scss";
import LogUserActivity from "../../components/LogUserActivity";

const CloroxData = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [alertError, setAlertError] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState(false);

  const [logId, setLogId] = useState(null);

  /* FILTER VARIABLES */
  const [yearFilters, setYearFilters] = useState([]);
  const [yearSelectedAll, setYearSelectedAll] = useState(false);
  const [yearsToShow, setYearsToShow] = useState([]);

  const [yearByCustomerSelectedAll, setYearByCustomerSelectedAll] =
    useState(false);
  const [yearByCustomersToShow, setYearByCustomersToShow] = useState([]);

  const [yearByCategorySelectedAll, setYearByCategorySelectedAll] =
    useState(false);
  const [yearByCategoryToShow, setYearByCategoryToShow] = useState([]);

  const [weekFilters, setWeekFilters] = useState([]);
  const [weekSelectedAll, setWeekSelectedAll] = useState(false);
  const [weeksToShow, setWeeksToShow] = useState([]);

  const [weekByCustomerSelectedAll, setWeekByCustomerSelectedAll] =
    useState(false);
  const [weekByCustomersToShow, setWeekByCustomersToShow] = useState([]);
  const [weekByCategorySelectedAll, setWeekByCategorySelectedAll] =
    useState(false);
  const [weekByCategoryToShow, setWeekByCategoryToShow] = useState([]);

  const [categoryFilters, setCategoryFilters] = useState([]);
  const [categorySelectedAll, setCategorySelectedAll] = useState(false);
  const [categoriesToShow, setCategoriesToShow] = useState([]);

  const [barGraphCustomerSelectedAll, setBarGraphCustomerSelectedAll] =
    useState(false);
  const [barGraphCustomersToShow, setBarGraphCustomersToShow] = useState([]);

  const [customerFilters, setCustomerFilters] = useState([]);
  const [customerSelectedAll, setCustomerSelectedAll] = useState(false);
  const [customersToShow, setCustomersToShow] = useState([]);

  /* FILTER SELECTED VARIABLES  */
  const [yearSelectedFilter, setYearSelectedFilter] = useState("");
  const [weekSelectedFilter, setWeekSelectedFilter] = useState(0);
  const [categorySelectedFilter, setCategorySelectedFilter] = useState("");
  const [customerSelectedFilter, setCustomerSelectedFilter] = useState("");
  const [channelSelectedValue, setChannelSelectedValue] = useState("");

  /* LINE GRAPH FILTER SELECTED VARIABLES */
  const [barGraphCategorySelectedAll, setBarGraphCategorySelectedAll] =
    useState(false);
  const [barGraphCategoriesToShow, setBarGraphCategoriesToShow] = useState([]);

  const [channelFilters, setChannelFilters] = useState([]);
  const [channelSelectedAll, setChannelSelectedAll] = useState(false);
  const [channelsToShow, setChannelsToShow] = useState([]);

  /* CHART VARIABLES */
  const [isCustomerGraphDataLoading, setIsCustomerGraphDataLoading] =
    useState(false);
  const [customerLineGraphData, setCustomerLineGraphData] = useState("");
  const [isCategoryGraphDataLoading, setIsCategoryGraphDataLoading] =
    useState(false);
  const [categoryLineGraphData, setCategoryLineGraphData] = useState("");

  /* CHART DATASET VARIABLES */
  const [categoryDataset, setCategoryDataset] = useState("");
  const [customerDataset, setCustomerDataset] = useState("");
  const [channelDataset, setChannelDataset] = useState("");

  useEffect(() => {
    const currentMonth = new Date().getMonth(); // Adding 1 because getMonth() returns a zero-based index
    const currentYear = new Date().getFullYear();
    let fiscalYearStartYear;

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    fiscalYearStartYear = currentMonth >= 6 ? currentYear : currentYear - 1;

    const fiscalYearLabel = `FY${fiscalYearStartYear.toString().slice(-2)}${(
      fiscalYearStartYear + 1
    )
      .toString()
      .slice(-2)}`;
    setYearsToShow([...yearsToShow, fiscalYearLabel]);
    setYearByCustomersToShow([...yearByCustomersToShow, fiscalYearLabel]);
    setYearByCategoryToShow([...yearByCategoryToShow, fiscalYearLabel]);
  }, []);

  useEffect(() => {
    setAlertError(null);

    const getFilterValues = async () => {
      setIsLoading(true);

      await axios({
        method: "post",
        url: "/api/filtervalues",
        headers: { "Content-Type": "application/json" },
        data: { category: categoriesToShow },
        timeout: 180000,
      })
        .then((response) => {
          setIsLoading(false);

          const responseData = response.data;

          setCategoryFilters(responseData.Category);
          setCustomerFilters(responseData.Customer);
          setWeekFilters(responseData.Week.map(String));
          setYearFilters(responseData.fiscalYear);
          setChannelFilters(responseData.channel);
        })
        .catch((error) => {
          console.log("Get Filters Api: ", error);
          setIsLoading(false);
          setAlertError("Something went wrong. Please try again.");
        });
    };
    LogUserActivity(
      "Clorox Data Activity Started",
      props?.currentUser?.user,
      setLogId,
      setAlertError
    );
    getFilterValues();
  }, []);

  useEffect(() => {
    setAlertError(null);

    const getCustomerLineChart = async () => {
      setIsCustomerGraphDataLoading(true);

      await axios({
        method: "post",
        url: "/api/line_graph_customer",
        data: {
          fiscalYear: yearByCustomersToShow,
          week: weekByCustomersToShow,
        },
      })
        .then((response) => {
          setIsCustomerGraphDataLoading(false);
          setCustomerLineGraphData(response.data);
        })
        .catch((error) => {
          console.log("Customer Line Graph Api: ", error);
          setIsCustomerGraphDataLoading(false);
          setAlertError("Something went wrong. Please try again.");
        });
    };

    if (yearByCustomersToShow || weekByCustomersToShow) {
      getCustomerLineChart();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [yearByCustomersToShow, weekByCustomersToShow]);

  useEffect(() => {
    setAlertError(null);
    const getCustomerLineChart = async () => {
      setIsCustomerGraphDataLoading(true);

      await axios({
        method: "post",
        url: "/api/line_graph_customer",
        data: {
          fiscalYear: yearByCustomersToShow,
          week: weekByCustomersToShow,
        },
      })
        .then((response) => {
          setIsCustomerGraphDataLoading(false);
          setCustomerLineGraphData(response.data);
        })
        .catch((error) => {
          console.log("Customer Line Graph Api: ", error);
          setIsCustomerGraphDataLoading(false);
          setAlertError("Something went wrong. Please try again.");
        });
    };

    const getCatergoryLineChart = async () => {
      setIsCategoryGraphDataLoading(true);

      await axios({
        method: "post",
        url: "/api/line_graph_clorox_category",
        data: {
          fiscalYear: yearByCategoryToShow,
          week: weekByCategoryToShow,
        },
      })
        .then((response) => {
          console.log(response, "line");
          setIsCategoryGraphDataLoading(false);
          setCategoryLineGraphData(response.data);
        })
        .catch((error) => {
          console.log("Category Line Graph Api: ", error);
          setIsCategoryGraphDataLoading(false);
          setAlertError("Something went wrong. Please try again.");
        });
    };
    if (yearByCategoryToShow || weekByCategoryToShow) {
      getCatergoryLineChart();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [yearByCategoryToShow, weekByCategoryToShow]);
  useEffect(() => {
    if (categoryFilters) {
      let filterArray = [];

      filterArray.push(
        categoryFilters.map((category) => {
          return category;
        })
      );

      setCategoriesToShow(filterArray[0]);
      setBarGraphCategoriesToShow(filterArray[0]);
      setCategorySelectedAll(true);
      setBarGraphCategorySelectedAll(true);
    }

    if (yearFilters) {
      let filterArray = [];

      filterArray.push(
        yearFilters.map((year) => {
          return year;
        })
      );

      // setYearsToShow(filterArray[0]);
      setYearSelectedAll(true);
    }
    if (weekFilters) {
      let filterArray = [];

      filterArray.push(
        weekFilters.map((week) => {
          return week;
        })
      );

      setWeeksToShow(filterArray[0]);
      setWeekSelectedAll(true);
    }
    if (customerFilters) {
      let filterArray = [];

      filterArray.push(
        customerFilters.map((week) => {
          return week;
        })
      );

      setCustomersToShow(filterArray[0]);
      setBarGraphCustomersToShow(filterArray[0]);
      setCustomerSelectedAll(true);
      setBarGraphCustomerSelectedAll(true);
    }
    if (channelFilters) {
      let filterArray = [];

      filterArray.push(
        channelFilters.map((week) => {
          return week;
        })
      );

      setChannelsToShow(filterArray[0]);
      setChannelSelectedAll(true);
    }
  }, [
    categoryFilters,
    yearFilters,
    weekFilters,
    customerFilters,
    channelFilters,
  ]);

  useEffect(() => {
    setAlertError(null);
    setIsLoading(false);
    const getFilteredValues = async () => {
      await axios({
        method: "post",
        url: "/api/filteredValues",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify({
          category: barGraphCategoriesToShow,
          customer: barGraphCustomersToShow,
          week: weeksToShow,
          channel: channelsToShow,
          fiscalYear: yearsToShow,
        }),
      })
        .then((response) => {
          const responseData = response.data;
          setIsLoading(false);
          setCategoryDataset(responseData.category);
          setCustomerDataset(responseData.customer);
          setChannelDataset(responseData.channel);
        })
        .catch((error) => {
          console.log("Filtered Values Api: ", error);
          setIsLoading(false);
          setAlertError("Something went wrong. Please try again.");
        });
    };

    if (
      barGraphCategoriesToShow &&
      barGraphCustomersToShow &&
      weeksToShow &&
      channelsToShow &&
      yearsToShow &&
      !filtersApplied
    ) {
      getFilteredValues();
    }
  }, []);

  const applyfilters = () => {
    setFiltersApplied(true);
    setIsLoading(true);
    setAlertError(null);

    const getFilteredValues = async () => {
      await axios({
        method: "post",
        url: "/api/filteredValues",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify({
          category: barGraphCategoriesToShow,
          customer: barGraphCustomersToShow,
          week: weeksToShow,
          channel: channelsToShow,
          fiscalYear: yearsToShow,
        }),
      })
        .then((response) => {
          const responseData = response.data;
          setIsLoading(false);
          setCategoryDataset(responseData.category);
          setCustomerDataset(responseData.customer);
          setChannelDataset(responseData.channel);
        })
        .catch((error) => {
          console.log("Filtered Values Api: ", error);
          setIsLoading(false);
          setAlertError("Something went wrong. Please try again.");
        });
    };

    getFilteredValues();
  };
  const handleReset = (flag) => {
    if (flag === "week") {
      setWeekSelectedFilter(0);
    }

    if (flag === "category") {
      setCategorySelectedFilter("");
    }

    if (flag === "customer") {
      setCustomerSelectedFilter("");
    }

    if (flag === "channel") {
      setChannelSelectedValue("");
    }

    if (flag === "year") {
      setYearSelectedFilter("");
    }
  };

  const selectAll = (e, flag) => {
    if (flag === "Categories") {
      if (!e.target.checked) {
        setCategoriesToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          categoryFilters.map((category) => {
            return category;
          })
        );

        setCategoriesToShow(filterArray[0]);
      }

      setCategorySelectedAll(e.target.checked);
    }
    if (flag === "barGraphCategories") {
      if (!e.target.checked) {
        setBarGraphCategoriesToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          categoryFilters.map((category) => {
            return category;
          })
        );

        setBarGraphCategoriesToShow(filterArray[0]);
      }

      setBarGraphCategorySelectedAll(e.target.checked);
    }
    if (flag === "barGraphCustomer") {
      if (!e.target.checked) {
        setBarGraphCustomersToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          customerFilters.map((customer) => {
            return customer;
          })
        );
        setBarGraphCustomersToShow(filterArray[0]);
      }

      setBarGraphCustomerSelectedAll(e.target.checked);
    }
    if (flag === "years") {
      if (!e.target.checked) {
        setYearsToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          yearFilters.map((year) => {
            return year;
          })
        );

        setYearsToShow(filterArray[0]);
      }

      setYearSelectedAll(e.target.checked);
    }
    if (flag === "weeks") {
      if (!e.target.checked) {
        setWeeksToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          weekFilters.map((week) => {
            return week;
          })
        );

        setWeeksToShow(filterArray[0]);
      }

      setWeekSelectedAll(e.target.checked);
    }

    if (flag === "weeksByCustomer") {
      if (!e.target.checked) {
        setWeekByCustomersToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          weekFilters.map((week) => {
            return week;
          })
        );

        setWeekByCustomersToShow(filterArray[0]);
      }

      setWeekByCustomerSelectedAll(e.target.checked);
    }
    if (flag === "yearByCustomer") {
      if (!e.target.checked) {
        setYearByCustomersToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          yearFilters.map((week) => {
            return week;
          })
        );

        setYearByCustomersToShow(filterArray[0]);
      }

      setYearByCustomerSelectedAll(e.target.checked);
    }
    if (flag === "weeksByCategory") {
      if (!e.target.checked) {
        setWeekByCategoryToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          weekFilters.map((week) => {
            return week;
          })
        );

        setWeekByCategoryToShow(filterArray[0]);
      }

      setWeekByCategorySelectedAll(e.target.checked);
    }
    if (flag === "yearByCategory") {
      if (!e.target.checked) {
        setYearByCategoryToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          yearFilters.map((week) => {
            return week;
          })
        );

        setYearByCategoryToShow(filterArray[0]);
      }

      setYearByCategorySelectedAll(e.target.checked);
    }

    if (flag === "Customers") {
      if (!e.target.checked) {
        setCustomersToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          customerFilters.map((customer) => {
            return customer;
          })
        );

        setCustomersToShow(filterArray[0]);
      }

      setCustomerSelectedAll(e.target.checked);
    }
    if (flag === "channels") {
      if (!e.target.checked) {
        setChannelsToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          channelFilters.map((channel) => {
            return channel;
          })
        );

        setChannelsToShow(filterArray[0]);
      }

      setChannelSelectedAll(e.target.checked);
    }
    // if (flag === "Competitions") {
    //     if (!e.target.checked) {
    //         setCompetitionsToShow([]);
    //     }
    //     else {
    //         let filterArray = [];

    //         filterArray.push(competitionFilters.map((competition) => {
    //             return competition;
    //         }));

    //         setCompetitionsToShow(filterArray[0]);
    //     }

    //     setCompetitionSelectedAll(e.target.checked);
    // }
  };

  const checkBoxFilterHandle = (e, flag) => {
    const { checked, value } = e.target;

    if (flag === "category") {
      if (checked) {
        const checkedValue = categoriesToShow.includes(value);

        if (!checkedValue) {
          const tempCategoryFilters = [...categoriesToShow, value];
          setCategoriesToShow(tempCategoryFilters);

          if (tempCategoryFilters.length === categoryFilters.length) {
            setCategorySelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = categoriesToShow.filter((element) => {
          return element !== value;
        });

        setCategoriesToShow(uncheckedValue);
        setCategorySelectedAll(false);
      }
    }

    if (flag === "barGraphCategories") {
      if (checked) {
        const checkedValue = barGraphCategoriesToShow.includes(value);

        if (!checkedValue) {
          const tempCategoryFilters = [...barGraphCategoriesToShow, value];
          setBarGraphCategoriesToShow(tempCategoryFilters);

          if (tempCategoryFilters.length === categoryFilters.length) {
            setCategorySelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = barGraphCategoriesToShow.filter((element) => {
          return element !== value;
        });

        setBarGraphCategoriesToShow(uncheckedValue);
        setBarGraphCategorySelectedAll(false);
      }
    }
    if (flag === "barGraphCustomer") {
      if (checked) {
        const checkedValue = barGraphCustomersToShow.includes(value);

        if (!checkedValue) {
          const tempCustomerFilters = [...barGraphCustomersToShow, value];
          setBarGraphCustomersToShow(tempCustomerFilters);

          if (tempCustomerFilters.length === customerFilters.length) {
            setCustomerSelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = barGraphCustomersToShow.filter((element) => {
          return element !== value;
        });

        setBarGraphCustomersToShow(uncheckedValue);
        setBarGraphCustomerSelectedAll(false);
      }
    }
    if (flag === "year") {
      if (checked) {
        const checkedValue = yearsToShow.includes(value);

        if (!checkedValue) {
          const tempyearFilters = [...yearsToShow, value];
          setYearsToShow(tempyearFilters);

          if (tempyearFilters.length === yearFilters.length) {
            setYearSelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = yearsToShow.filter((element) => {
          return element !== value;
        });

        setYearsToShow(uncheckedValue);
        setYearSelectedAll(false);
      }
    }

    if (flag === "yearByCustomer") {
      if (checked) {
        const checkedValue = yearByCustomersToShow.includes(value);

        if (!checkedValue) {
          const tempyearFilters = [...yearByCustomersToShow, value];
          setYearByCustomersToShow(tempyearFilters);

          if (tempyearFilters.length === yearFilters.length) {
            setYearByCustomerSelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = yearByCustomersToShow.filter((element) => {
          return element !== value;
        });

        setYearByCustomersToShow(uncheckedValue);
        setYearByCustomerSelectedAll(false);
      }
    }
    if (flag === "yearByCategory") {
      if (checked) {
        const checkedValue = yearByCategoryToShow.includes(value);

        if (!checkedValue) {
          const tempyearFilters = [...yearByCategoryToShow, value];
          setYearByCategoryToShow(tempyearFilters);

          if (tempyearFilters.length === yearFilters.length) {
            setYearByCategorySelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = yearByCategoryToShow.filter((element) => {
          return element !== value;
        });

        setYearByCategoryToShow(uncheckedValue);
        setYearByCategorySelectedAll(false);
      }
    }
    if (flag === "weeksByCustomer") {
      // const checkedValue = weeksToShow.includes(value);
      // console.log(checkedValue)
      if (checked) {
        const checkedValue = weekByCustomersToShow.includes(value);
        console.log(value);
        if (!checkedValue) {
          const tempweekFilters = [...weekByCustomersToShow, value];
          setWeekByCustomersToShow(tempweekFilters);

          if (tempweekFilters.length === weekFilters.length) {
            setWeekByCustomerSelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = weekByCustomersToShow.filter((element) => {
          return element !== value;
        });

        setWeekByCustomersToShow(uncheckedValue);
        setWeekByCustomerSelectedAll(false);
      }
    }
    if (flag === "weeksByCategory") {
      // const checkedValue = weeksToShow.includes(value);
      // console.log(checkedValue)
      if (checked) {
        const checkedValue = weekByCategoryToShow.includes(value);
        console.log(value);
        if (!checkedValue) {
          const tempweekFilters = [...weekByCategoryToShow, value];
          setWeekByCategoryToShow(tempweekFilters);

          if (tempweekFilters.length === weekFilters.length) {
            setWeekByCategorySelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = weekByCategoryToShow.filter((element) => {
          return element !== value;
        });

        setWeekByCategoryToShow(uncheckedValue);
        setWeekByCategorySelectedAll(false);
      }
    }

    if (flag === "week") {
      // const checkedValue = weeksToShow.includes(value);
      // console.log(checkedValue)
      if (checked) {
        const checkedValue = weeksToShow.includes(value);
        console.log(value);
        if (!checkedValue) {
          const tempweekFilters = [...weeksToShow, value];
          setWeeksToShow(tempweekFilters);

          if (tempweekFilters.length === weekFilters.length) {
            setWeekSelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = weeksToShow.filter((element) => {
          return element !== value;
        });

        setWeeksToShow(uncheckedValue);
        setWeekSelectedAll(false);
      }
    }
    if (flag === "customer") {
      // const checkedValue = weeksToShow.includes(value);
      // console.log(checkedValue)
      if (checked) {
        const checkedValue = customersToShow.includes(value);
        console.log(value);
        if (!checkedValue) {
          const tempweekFilters = [...customersToShow, value];
          setCustomersToShow(tempweekFilters);

          if (tempweekFilters.length === weekFilters.length) {
            setCustomerSelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = customersToShow.filter((element) => {
          return element !== value;
        });

        setCustomersToShow(uncheckedValue);
        setCustomerSelectedAll(false);
      }
    }

    if (flag === "channel") {
      // const checkedValue = weeksToShow.includes(value);
      // console.log(checkedValue)
      if (checked) {
        const checkedValue = channelsToShow.includes(value);
        console.log(value);
        if (!checkedValue) {
          const tempweekFilters = [...channelsToShow, value];
          setChannelsToShow(tempweekFilters);

          if (tempweekFilters.length === weekFilters.length) {
            setChannelSelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = channelsToShow.filter((element) => {
          return element !== value;
        });

        setChannelsToShow(uncheckedValue);
        setChannelSelectedAll(false);
      }
    }
  };

  return (
    <div className="container-fluid feature_data position-relative">
      <h2 className="heading_sm_center">Clorox Data</h2>

      {isLoading ? (
        <div className="col-12 d-flex justify-content-center loader">
          <br />
          <div
            className="spinner-border text-info"
            role="status"
            style={{ zIndex: "2" }}
          ></div>
          <div className="loader-message">Loading...</div>
        </div>
      ) : alertError ? (
        <Alert variant="danger">{alertError}</Alert>
      ) : (
        <div className="row content_main_row">
          <div className="filters col-md-12">
            <div className="row w-100 filters_row justify-content-between">
              <div className="col py-0">
                <div
                  className="filters_right w-100 justify-content-center"
                  style={{ gap: "5px" }}
                >
                  <div className="filter-dropdowns">
                    <BiReset onClick={() => handleReset("year")} />
                    <Dropdown
                      className={`${
                        !(yearsToShow.length > 0)
                          ? "dropDown_disabled"
                          : "dropdown_selected"
                      }`}
                    >
                      <Dropdown.Toggle variant="secondary">
                        Year
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div
                          className="p-3 d-flex flex-column"
                          style={{ gap: "5px" }}
                        >
                          <div className="d-flex" style={{ gap: "0 8px" }}>
                            <input
                              type="checkbox"
                              name="selectAll"
                              checked={yearSelectedAll}
                              onChange={(e) => selectAll(e, "years")}
                            />
                            <label htmlFor="selectAll">Select All</label>
                          </div>

                          {yearFilters?.map((year, index) => (
                            <div
                              key={index}
                              className="d-flex"
                              style={{ gap: "0 10px" }}
                            >
                              <input
                                type="checkbox"
                                value={year}
                                onChange={(e) =>
                                  checkBoxFilterHandle(e, "year")
                                }
                                checked={
                                  yearsToShow.includes(year) ? true : false
                                }
                              />
                              <label style={{ fontSize: "12px" }}>{year}</label>
                            </div>
                          ))}
                        </div>
                      </Dropdown.Menu>
                      {/* <Dropdown.Menu>
                                                {yearFilters?.map((filter, index) =>
                                                    <Dropdown.Item key={index} onClick={(event) => setYearSelectedFilter(event.target.text)}>{filter}</Dropdown.Item>
                                                )}
                                            // </Dropdown.Menu> */}
                    </Dropdown>
                  </div>

                  <div className="filter-dropdowns">
                    <BiReset onClick={() => handleReset("week")} />
                    <Dropdown
                      className={`${
                        !(weeksToShow.length > 0)
                          ? "dropDown_disabled"
                          : "dropdown_selected"
                      }`}
                    >
                      <Dropdown.Toggle variant="secondary">
                        Week
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div
                          className="p-3 d-flex flex-column"
                          style={{ gap: "5px" }}
                        >
                          <div className="d-flex" style={{ gap: "0 8px" }}>
                            <input
                              type="checkbox"
                              name="selectAll"
                              checked={weekSelectedAll}
                              onChange={(e) => selectAll(e, "weeks")}
                            />
                            <label htmlFor="selectAll">Select All</label>
                          </div>

                          {weekFilters?.map((week, index) => (
                            <div
                              key={index}
                              className="d-flex"
                              style={{ gap: "0 10px" }}
                            >
                              <input
                                type="checkbox"
                                value={week}
                                onChange={(e) =>
                                  checkBoxFilterHandle(e, "week")
                                }
                                checked={
                                  weeksToShow.includes(week) ? true : false
                                }
                              />
                              <label style={{ fontSize: "12px" }}>{week}</label>
                            </div>
                          ))}
                        </div>
                      </Dropdown.Menu>
                      {/* <Dropdown.Menu>
                                                {weekFilters?.map((filter, index) =>
                                                    <Dropdown.Item key={index} onClick={(event) => setWeekSelectedFilter(event.target.text)}>{filter}</Dropdown.Item>
                                                )}
                                            </Dropdown.Menu> */}
                    </Dropdown>
                  </div>
                </div>
              </div>

              <div className="col-md-8 col-xl-7">
                <div
                  className="filters_right w-100 justify-content-center"
                  style={{ gap: "5px" }}
                >
                  <div className="">
                    <BiReset
                      onClick={() => handleReset("barGraphCategories")}
                    />
                    <Dropdown
                      className={`${
                        !(barGraphCategoriesToShow.length > 0)
                          ? "dropDown_disabled"
                          : "dropdown_selected"
                      }`}
                    >
                      <Dropdown.Toggle variant="secondary">
                        Category
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <div
                          className="p-3 d-flex flex-column"
                          style={{ gap: "5px" }}
                        >
                          <div className="d-flex" style={{ gap: "0 8px" }}>
                            <input
                              type="checkbox"
                              name="selectAll"
                              checked={barGraphCategorySelectedAll}
                              onChange={(e) =>
                                selectAll(e, "barGraphCategories")
                              }
                            />
                            <label htmlFor="selectAll">Select All</label>
                          </div>

                          {categoryFilters?.map((category, index) => (
                            <div
                              key={index}
                              className="d-flex"
                              style={{ gap: "0 10px" }}
                            >
                              <input
                                type="checkbox"
                                value={category}
                                onChange={(e) =>
                                  checkBoxFilterHandle(e, "barGraphCategories")
                                }
                                checked={
                                  barGraphCategoriesToShow.includes(category)
                                    ? true
                                    : false
                                }
                              />
                              <label style={{ fontSize: "12px" }}>
                                {category}
                              </label>
                            </div>
                          ))}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <div className="">
                    <BiReset onClick={() => handleReset("barGraphcustomer")} />
                    <Dropdown
                      className={`${
                        !(barGraphCustomersToShow.length > 0)
                          ? "dropDown_disabled"
                          : "dropdown_selected"
                      }`}
                    >
                      <Dropdown.Toggle variant="secondary">
                        Customer
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div
                          className="p-3 d-flex flex-column"
                          style={{ gap: "5px" }}
                        >
                          <div className="d-flex" style={{ gap: "0 8px" }}>
                            <input
                              type="checkbox"
                              name="selectAll"
                              checked={barGraphCustomerSelectedAll}
                              onChange={(e) => selectAll(e, "barGraphCustomer")}
                            />
                            <label htmlFor="selectAll">Select All</label>
                          </div>

                          {customerFilters?.map((customer, index) => (
                            <div
                              key={index}
                              className="d-flex"
                              style={{ gap: "0 10px" }}
                            >
                              <input
                                type="checkbox"
                                value={customer}
                                onChange={(e) =>
                                  checkBoxFilterHandle(e, "barGraphCustomer")
                                }
                                checked={
                                  barGraphCustomersToShow.includes(customer)
                                    ? true
                                    : false
                                }
                              />
                              <label style={{ fontSize: "12px" }}>
                                {customer}
                              </label>
                            </div>
                          ))}
                        </div>
                      </Dropdown.Menu>
                      {/* <Dropdown.Menu>
                                                {customerFilters?.map((filter, index) =>
                                                    <Dropdown.Item key={index} onClick={(event) => setCustomerSelectedFilter(event.target.text)}>{filter}</Dropdown.Item>
                                                )}
                                            </Dropdown.Menu> */}
                    </Dropdown>
                  </div>

                  <div className="">
                    <BiReset onClick={() => handleReset("channel")} />
                    <Dropdown
                      className={`${
                        !(channelsToShow.length > 0)
                          ? "dropDown_disabled"
                          : "dropdown_selected"
                      }`}
                    >
                      <Dropdown.Toggle variant="secondary">
                        Channel
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <div
                          className="p-3 d-flex flex-column"
                          style={{ gap: "5px" }}
                        >
                          <div className="d-flex" style={{ gap: "0 8px" }}>
                            <input
                              type="checkbox"
                              name="selectAll"
                              checked={channelSelectedAll}
                              onChange={(e) => selectAll(e, "channels")}
                            />
                            <label htmlFor="selectAll">Select All</label>
                          </div>

                          {channelFilters?.map((channel, index) => (
                            <div
                              key={index}
                              className="d-flex"
                              style={{ gap: "0 10px" }}
                            >
                              <input
                                type="checkbox"
                                value={channel}
                                onChange={(e) =>
                                  checkBoxFilterHandle(e, "channel")
                                }
                                checked={
                                  channelsToShow.includes(channel)
                                    ? true
                                    : false
                                }
                              />
                              <label style={{ fontSize: "12px" }}>
                                {channel}
                              </label>
                            </div>
                          ))}
                        </div>
                      </Dropdown.Menu>
                      {/* <Dropdown.Menu>
                                                {channelFilters?.map((filter, index) =>
                                                    <Dropdown.Item key={index} onClick={(event) => setChannelSelectedFilter(event.target.text)}>{filter}</Dropdown.Item>
                                                )}
                                            </Dropdown.Menu> */}
                    </Dropdown>
                  </div>
                  <button className="btn btn-success" onClick={applyfilters}>
                    Apply Filters
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-5">
            <div className="row w-100 flex-column align-items-center">
              <div className="col-12 my_graph">
                <DoubleBarChart dataset={categoryDataset} label={"Category"} />
              </div>
            </div>
          </div>

          <div className="col-md-5">
            <div className="row w-100 flex-column align-items-center">
              <div className="col-12 my_graph">
                <DoubleBarChart dataset={customerDataset} label={"Customer"} />
              </div>
            </div>
          </div>

          <div className="col-md-5">
            <div className="row w-100 flex-column align-items-center">
              <div className="col-12 my_graph">
                <DoubleBarChart dataset={channelDataset} label={"Channel"} />
              </div>
            </div>
          </div>

          <hr className="m-2" />

          <div className="col-md-12">
            <h3 className="mb-4">By Week By Customer Trend</h3>

            <div className="row w-100 justify-content-center filters_right">
              <div className="col-5 col-md-2 d-flex justify-content-between">
                <div className="filter-dropdowns">
                  <Dropdown
                    className={`${
                      !(yearByCustomersToShow.length > 0)
                        ? "dropDown_disabled"
                        : "dropdown_selected"
                    }`}
                  >
                    <Dropdown.Toggle variant="secondary">Year</Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div
                        className="p-3 d-flex flex-column"
                        style={{ gap: "5px" }}
                      >
                        <div className="d-flex" style={{ gap: "0 8px" }}>
                          <input
                            type="checkbox"
                            name="selectAll"
                            checked={yearByCustomerSelectedAll}
                            onChange={(e) => selectAll(e, "yearByCustomer")}
                          />
                          <label htmlFor="selectAll">Select All</label>
                        </div>

                        {yearFilters?.map((year, index) => (
                          <div
                            key={index}
                            className="d-flex"
                            style={{ gap: "0 10px" }}
                          >
                            <input
                              type="checkbox"
                              value={year}
                              onChange={(e) =>
                                checkBoxFilterHandle(e, "yearByCustomer")
                              }
                              checked={
                                yearByCustomersToShow.includes(year)
                                  ? true
                                  : false
                              }
                            />
                            <label style={{ fontSize: "12px" }}>{year}</label>
                          </div>
                        ))}
                      </div>
                    </Dropdown.Menu>
                    {/* <Dropdown.Menu>
                                                {yearFilters?.map((filter, index) =>
                                                    <Dropdown.Item key={index} onClick={(event) => setYearSelectedFilter(event.target.text)}>{filter}</Dropdown.Item>
                                                )}
                                            // </Dropdown.Menu> */}
                  </Dropdown>
                </div>
              </div>
              <div className="col-5 col-md-2 d-flex justify-content-between">
                <div className="filter-dropdowns">
                  <Dropdown
                    className={`${
                      !(weekByCustomersToShow.length > 0)
                        ? "dropDown_disabled"
                        : "dropdown_selected"
                    }`}
                  >
                    <Dropdown.Toggle variant="secondary">Week</Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div
                        className="p-3 d-flex flex-column"
                        style={{ gap: "5px" }}
                      >
                        <div className="d-flex" style={{ gap: "0 8px" }}>
                          <input
                            type="checkbox"
                            name="selectAll"
                            checked={weekByCustomerSelectedAll}
                            onChange={(e) => selectAll(e, "weeksByCustomer")}
                          />
                          <label htmlFor="selectAll">Select All</label>
                        </div>

                        {weekFilters?.map((week, index) => (
                          <div
                            key={index}
                            className="d-flex"
                            style={{ gap: "0 10px" }}
                          >
                            <input
                              type="checkbox"
                              value={week}
                              onChange={(e) =>
                                checkBoxFilterHandle(e, "weeksByCustomer")
                              }
                              checked={
                                weekByCustomersToShow.includes(week)
                                  ? true
                                  : false
                              }
                            />
                            <label style={{ fontSize: "12px" }}>{week}</label>
                          </div>
                        ))}
                      </div>
                    </Dropdown.Menu>
                    {/* <Dropdown.Menu>
                                                {weekFilters?.map((filter, index) =>
                                                    <Dropdown.Item key={index} onClick={(event) => setWeekSelectedFilter(event.target.text)}>{filter}</Dropdown.Item>
                                                )}
                                            </Dropdown.Menu> */}
                  </Dropdown>
                </div>
              </div>
              <div className="col-5 col-md-2 d-flex justify-content-between">
                <div className="filter-dropdowns">
                  <Dropdown
                    className={`${
                      customersToShow.length !== 0
                        ? "dropdown_selected"
                        : "dropDown_disabled"
                    }`}
                  >
                    <Dropdown.Toggle variant="secondary">
                      Customers
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <div
                        className="p-3 d-flex flex-column"
                        style={{ gap: "5px" }}
                      >
                        <div className="d-flex" style={{ gap: "0 8px" }}>
                          <input
                            type="checkbox"
                            name="selectAll"
                            checked={customerSelectedAll}
                            onChange={(e) => selectAll(e, "Customers")}
                          />
                          <label htmlFor="selectAll">Select All</label>
                        </div>

                        {customerFilters?.map((customer, index) => (
                          <div
                            key={index}
                            className="d-flex"
                            style={{ gap: "0 10px" }}
                          >
                            <input
                              type="checkbox"
                              value={customer}
                              onChange={(e) =>
                                checkBoxFilterHandle(e, "customer")
                              }
                              checked={
                                customersToShow.includes(customer)
                                  ? true
                                  : false
                              }
                            />
                            <label style={{ fontSize: "12px" }}>
                              {customer}
                            </label>
                          </div>
                        ))}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-8 my_graph">
                <LineChart
                  loading={isCustomerGraphDataLoading}
                  lineGraphData={customerLineGraphData?.data}
                  weeks={customerLineGraphData?.weeks}
                  colors={customerLineGraphData?.color}
                  valuesToShow={customersToShow}
                  customHeight={450}
                />
              </div>
            </div>
          </div>

          <hr className="m-2" />

          <div className="col-md-12">
            <h3 className="mb-4">By Week By Category Trend</h3>

            <div className="row w-100 justify-content-center filters_right">
              <div className="col-5 col-md-2 d-flex justify-content-between">
                <div className="filter-dropdowns">
                  <Dropdown
                    className={`${
                      !(yearByCategoryToShow.length > 0)
                        ? "dropDown_disabled"
                        : "dropdown_selected"
                    }`}
                  >
                    <Dropdown.Toggle variant="secondary">Year</Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div
                        className="p-3 d-flex flex-column"
                        style={{ gap: "5px" }}
                      >
                        <div className="d-flex" style={{ gap: "0 8px" }}>
                          <input
                            type="checkbox"
                            name="selectAll"
                            checked={yearByCategorySelectedAll}
                            onChange={(e) => selectAll(e, "yearByCategory")}
                          />
                          <label htmlFor="selectAll">Select All</label>
                        </div>

                        {yearFilters?.map((year, index) => (
                          <div
                            key={index}
                            className="d-flex"
                            style={{ gap: "0 10px" }}
                          >
                            <input
                              type="checkbox"
                              value={year}
                              onChange={(e) =>
                                checkBoxFilterHandle(e, "yearByCategory")
                              }
                              checked={
                                yearByCategoryToShow.includes(year)
                                  ? true
                                  : false
                              }
                            />
                            <label style={{ fontSize: "12px" }}>{year}</label>
                          </div>
                        ))}
                      </div>
                    </Dropdown.Menu>
                    {/* <Dropdown.Menu>
                                                {yearFilters?.map((filter, index) =>
                                                    <Dropdown.Item key={index} onClick={(event) => setYearSelectedFilter(event.target.text)}>{filter}</Dropdown.Item>
                                                )}
                                            // </Dropdown.Menu> */}
                  </Dropdown>
                </div>
              </div>
              <div className="col-5 col-md-2 d-flex justify-content-between">
                <div className="filter-dropdowns">
                  <Dropdown
                    className={`${
                      !(weekByCategoryToShow.length > 0)
                        ? "dropDown_disabled"
                        : "dropdown_selected"
                    }`}
                  >
                    <Dropdown.Toggle variant="secondary">Week</Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div
                        className="p-3 d-flex flex-column"
                        style={{ gap: "5px" }}
                      >
                        <div className="d-flex" style={{ gap: "0 8px" }}>
                          <input
                            type="checkbox"
                            name="selectAll"
                            checked={weekByCategorySelectedAll}
                            onChange={(e) => selectAll(e, "weeksByCategory")}
                          />
                          <label htmlFor="selectAll">Select All</label>
                        </div>

                        {weekFilters?.map((week, index) => (
                          <div
                            key={index}
                            className="d-flex"
                            style={{ gap: "0 10px" }}
                          >
                            <input
                              type="checkbox"
                              value={week}
                              onChange={(e) =>
                                checkBoxFilterHandle(e, "weeksByCategory")
                              }
                              checked={
                                weekByCategoryToShow.includes(week)
                                  ? true
                                  : false
                              }
                            />
                            <label style={{ fontSize: "12px" }}>{week}</label>
                          </div>
                        ))}
                      </div>
                    </Dropdown.Menu>
                    {/* <Dropdown.Menu>
                                                {weekFilters?.map((filter, index) =>
                                                    <Dropdown.Item key={index} onClick={(event) => setWeekSelectedFilter(event.target.text)}>{filter}</Dropdown.Item>
                                                )}
                                            </Dropdown.Menu> */}
                  </Dropdown>
                </div>
              </div>
              <div className="col-5 col-md-2 d-flex justify-content-between">
                <div className="filter-dropdowns">
                  <Dropdown
                    className={`${
                      categoriesToShow.length !== 0
                        ? "dropdown_selected"
                        : "dropDown_disabled"
                    }`}
                  >
                    <Dropdown.Toggle variant="secondary">
                      Categories
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <div
                        className="p-3 d-flex flex-column"
                        style={{ gap: "5px" }}
                      >
                        <div className="d-flex" style={{ gap: "0 8px" }}>
                          <input
                            type="checkbox"
                            name="selectAll"
                            checked={categorySelectedAll}
                            onChange={(e) => selectAll(e, "Categories")}
                          />
                          <label htmlFor="selectAll">Select All</label>
                        </div>

                        {categoryFilters?.map((category, index) => (
                          <div
                            key={index}
                            className="d-flex"
                            style={{ gap: "0 10px" }}
                          >
                            <input
                              type="checkbox"
                              value={category}
                              onChange={(e) =>
                                checkBoxFilterHandle(e, "category")
                              }
                              checked={
                                categoriesToShow.includes(category)
                                  ? true
                                  : false
                              }
                            />
                            <label style={{ fontSize: "12px" }}>
                              {category}
                            </label>
                          </div>
                        ))}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-8 my_graph">
                <LineChart
                  loading={isCategoryGraphDataLoading}
                  lineGraphData={categoryLineGraphData?.data}
                  weeks={categoryLineGraphData?.weeks}
                  colors={categoryLineGraphData?.color}
                  valuesToShow={categoriesToShow}
                  customHeight={450}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CloroxData;
