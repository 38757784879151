import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ isAllowed, redirectPath = '/login', children }) => 
{
    const location = useLocation();

    if (!isAllowed) 
    {
        return <Navigate to = {redirectPath} replace = {true} state = {{ path: location?.pathname }} />;
    }

    return children ? children : <Outlet />;
};

export default ProtectedRoute;