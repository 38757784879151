import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import FormControl from 'react-bootstrap/FormControl';

const SelectbarWithSearchOption = ({ optionsArray, setSelectedValue, selectedValue }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleOptionClick = (storeName) => {
    setSelectedValue(storeName);
    setSearchQuery('');
  };

  const filteredOptions = optionsArray.filter(option =>
    option.storeName?.toLowerCase().includes(searchQuery?.toLowerCase())
  );

  return (
    <Dropdown className={`${!(selectedValue) ? 'dropDown_disabled' : 'dropdown_selected'}`}>
      <Dropdown.Toggle variant="secondary">
        Store
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <FormControl
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        {filteredOptions.map(option => (
          <Dropdown.Item
            key={option.storeCode}
            onClick={() => handleOptionClick(option.storeName)}
            className={selectedValue === option.storeName ? 'active' : ''}
          >
            {`${option.storeName} (${option.storeCode})`}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SelectbarWithSearchOption;
