import React, { useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { setRole } from '../actions/userRole';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import ProtectedRoute from './ProtectedRoute';
import AdminPanel from '../adminPanel/AdminPanel';
import Login from '../pages/authorizeUser/Login';
import ResetPassword from '../pages/authorizeUser/ResetPassword';
import ChangePassword from '../pages/authorizeUser/ChangePassword';
import CloroxData from '../pages/featureData/CloroxData';
import CloroxAndCompetitionData from '../pages/featureData/CloroxAndCompetitionData';
import TableView from '../pages/featureData/TableView';
import MonitorPerformance from '../pages/monitorAnalytics/MonitorPerformance';
import MonitorRoute from '../pages/monitorAnalytics/MonitorRoute';
import TargetVsActual from '../pages/monitorAnalytics/TargetVsActual';
import PageNotFound from '../PageNotFound';
import Templates from '../pages/monitorAnalytics/Templates';
import OnSehlfAvailability from '../pages/monitorAnalytics/OnSehlfAvailability';
import ShareOfShelf from '../pages/monitorAnalytics/ShareOfShelf';
import TargetVsActualPerDay from '../pages/monitorAnalytics/TargetVsActualPerDay';
import Planogram from '../pages/monitorAnalytics/Planogram';
import SecondaryDisplay from '../pages/monitorAnalytics/SecondaryDisplay';
import Summary from '../pages/monitorAnalytics/Summary';
import RSP from '../pages/monitorAnalytics/RSP';
import DisplayImage from '../pages/DisplayImage';
import ImageDownload from '../pages/monitorAnalytics/imageDownload';
import Photos from '../pages/photoHub/Photos';
import PhotoDetail from '../pages/photoHub/PhotoDetail';

function RouterLinks(props) {
    const [currentUser, setCurrentUser] = useState(props?.userRole || null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLogout = () => {
        if (currentUser) {
            setCurrentUser(null);
            dispatch(setRole(0, null, null));
            localStorage.setItem("IsLoggedOut", true);
            navigate("/login");
        }
    }

    return (
        <Routes>
            <Route path="/login" element={
                <Login currentUser={currentUser} setCurrentUser={setCurrentUser} />}
            />

            <Route path="/login/reset-password" element={
                <ResetPassword currentUser={currentUser} />}
            />

            <Route path="/login/change-password/user/:id" element={
                <ChangePassword currentUser={currentUser} />}
            />

            <Route element={<ProtectedRoute isAllowed={!!currentUser?.user} />}>
                <Route path="/" element={
                    <AdminPanel currentUser={currentUser} component={<CloroxData currentUser={currentUser} />} logOut={handleLogout} />
                } />

                <Route path="/feature-data/clorox-data" element={
                    <AdminPanel currentUser={currentUser} component={<CloroxData currentUser={currentUser} />} logOut={handleLogout} />
                } />

                <Route path="/feature-data/clorox-and-competition-data" element={
                    <AdminPanel currentUser={currentUser} component={<CloroxAndCompetitionData currentUser={currentUser} />} logOut={handleLogout} />
                } />

                <Route path="/feature-data/table-view" element={
                    <AdminPanel currentUser={currentUser} component={<TableView currentUser={currentUser} />} logOut={handleLogout} />
                } />

                <Route path="/monitor-analytics/monitor-performance" element={
                    <AdminPanel currentUser={currentUser} component={<MonitorPerformance currentUser={currentUser} />} logOut={handleLogout} />
                } />

                <Route path="/monitor-analytics/monitor-route" element={
                    <AdminPanel currentUser={currentUser} component={<MonitorRoute currentUser={currentUser} />} logOut={handleLogout} />
                } />

                <Route path="/monitor-analytics/target-vs-actual" element={
                    <AdminPanel currentUser={currentUser} component={<TargetVsActual currentUser={currentUser} />} logOut={handleLogout} />
                } />
                <Route path="/monitor-analytics/target-vs-actual-per-day" element={
                    <AdminPanel currentUser={currentUser} component={<TargetVsActualPerDay currentUser={currentUser} />} logOut={handleLogout} />
                } />
                <Route path="/monitor-analytics/templates" element={
                    <AdminPanel currentUser={currentUser} component={<Templates currentUser={currentUser} />} logOut={handleLogout} />
                } />
                <Route path="/monitor-analytics/image-download" element={
                    <AdminPanel currentUser={currentUser} component={< ImageDownload currentUser={currentUser} />} logOut={handleLogout} />
                } />
                <Route path="/monitor-analytics/availability" element={
                    <AdminPanel currentUser={currentUser} component={<OnSehlfAvailability currentUser={currentUser} />} logOut={handleLogout} />
                } />
                <Route path="/monitor-analytics/share-of-shelf" element={
                    <AdminPanel currentUser={currentUser} component={<ShareOfShelf currentUser={currentUser} />} logOut={handleLogout} />
                } />
                <Route path="/monitor-analytics/planogram" element={
                    <AdminPanel currentUser={currentUser} component={<Planogram currentUser={currentUser} />} logOut={handleLogout} />
                } />
                <Route path="/monitor-analytics/secondary-display" element={
                    <AdminPanel currentUser={currentUser} component={<SecondaryDisplay currentUser={currentUser} />} logOut={handleLogout} />
                } />
                <Route path="/monitor-analytics/summary" element={
                    <AdminPanel currentUser={currentUser} component={<Summary currentUser={currentUser} />} logOut={handleLogout} />
                } />
                <Route path="/monitor-analytics/RSP" element={
                    <AdminPanel currentUser={currentUser} component={<RSP currentUser={currentUser} />} logOut={handleLogout} />
                } />
                <Route path="/display-image/:img" element={
                    <AdminPanel currentUser={currentUser} component={<DisplayImage currentUser={currentUser} />} logOut={handleLogout} />
                } />
                <Route path="/display-image-secondary/:img" element={
                    <AdminPanel currentUser={currentUser} component={<DisplayImage currentUser={currentUser} />} logOut={handleLogout} />
                } />
                  <Route path="/photo-hub/photos" element={
                    <AdminPanel currentUser={currentUser} component={<Photos currentUser={currentUser} />} logOut={handleLogout} />
                } />
                  <Route path="/photoHub/photo-details/:id" element={
                    <AdminPanel currentUser={currentUser} component={<PhotoDetail currentUser={currentUser} />} logOut={handleLogout} />
                } />
                
            </Route>

            <Route path="*" element={<PageNotFound />} />
        </Routes>
    );
}

const mapStateToProps = state => {
    return {
        userRole: state.userRole
    };
}

export default connect(mapStateToProps)(RouterLinks);