import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import { useParams, useLocation  } from "react-router-dom";

const DisplayImage = () => {
  const { img } = useParams();
  const location = useLocation();
  const [imageLink, setImageLink] = useState(null);
  useEffect(() => {
    if(location?.pathname.includes("display-image-secondary")){
      var apiUrl = "/api/get_image_secondary_display"
    }else{
      var apiUrl = "/api/get_image"
    }
    const getImage = async () => {
      await axios({
        method: "post",
        url: apiUrl,
        headers: { "Content-Type": "application/json" },
        data: { filename: img },
      })
        .then((response) => {
          const imageData = response?.data?.image;
          const decodedImage = atob(imageData);
          const arrayBuffer = new ArrayBuffer(decodedImage.length);
          const uintArray = new Uint8Array(arrayBuffer);
          for (let i = 0; i < decodedImage.length; i++) {
            uintArray[i] = decodedImage.charCodeAt(i);
          }
          const blob = new Blob([uintArray], { type: "image/png" });
          const urlCreator = window.URL || window.webkitURL;
          const imageUrl = urlCreator.createObjectURL(blob);
          setImageLink(imageUrl);
        })
        .catch((error) => {
          console.log("Get Filters Api: ", error);
        });
    };

    getImage();
  }, []);

  return (
    <div>
      {!imageLink ? (
        <div className="col-12 d-flex justify-content-center loader">
          <br />
          <div
            className="spinner-border text-info"
            role="status"
            style={{ zIndex: "2" }}
          ></div>
          <div className="mt-2">Loading...</div>
        </div>
      ) : (
        <ReactPanZoom image={imageLink} alt="Image alt text" />
      )}
    </div>
  );
};

export default DisplayImage;
