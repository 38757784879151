import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = (props) => {
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 992px)").matches
  );
  const [height, setHeight] = useState();

  useEffect(() => {
    const handleResize = (e) => {
      setMatches(e.matches);
    };

    const mediaQuery = window.matchMedia("(max-width: 992px)");
    mediaQuery.addEventListener("change", handleResize);

    return () => {
      mediaQuery.removeEventListener("change", handleResize);
    };
  }, []);

  useEffect(() => {
    if (props?.customHeight) {
      setHeight(props.customHeight);
    }
  }, [matches, props?.customHeight]);

  function getUniqueColor(n) {
    const rgb = [0, 0, 0];
    for (let i = 0; i < 24; i++) {
      rgb[i % 3] <<= 1;
      rgb[i % 3] |= n & 0x01;
      n >>= 1;
    }
    return (
      "#" +
      rgb.reduce(
        (a, c) => (c > 0x0f ? c.toString(16) : "0" + c.toString(16)) + a,
        ""
      )
    );
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    text: "Primary Sales",
    fontSize: 50,
    fontColor: "black",
    plugins: {
      title: {
        display: false,
        text: "Custom Chart Title",
        color: "#EE259A",
      },
      datalabels: {
        display: true,
        color: "#ffffff",
        // align: "end",
        // anchor: "end",
        // font: { size: "12" }
      },
      legend: {
        position: "top",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
        },
      },
      tooltip: {
        // backgroundColor:'#085986',
        callbacks: {
          label: function (context) {
            let label = context.label;
            let value = context.formattedValue;

            if (!label) label = "Unknown";

            let sum = 0;
            let dataArr = context.chart.data.datasets[0].data;
            dataArr.map((data) => {
              sum += Number(data);
            });

            let percentage = ((value * 100) / sum).toFixed(2) + "%";
            return label + ": " + percentage;
          },
        },
      },
    },
  };

  const labels = props?.labels;
  const datasets = [
    {
      label: "# of Votes",
      data: props?.data,
      backgroundColor: ["#00225D", "#0A5A87"],
      // borderColor: [
      //     'rgba(255, 99, 132, 1)',
      //     'rgba(54, 162, 235, 1)',
      //     'rgba(255, 206, 86, 1)',
      //     'rgba(75, 192, 192, 1)',
      //     'rgba(153, 102, 255, 1)',
      //     'rgba(255, 159, 64, 1)',
      // ],
      borderWidth: 1,
    },
  ];

  const data = {
    labels,
    datasets,
  };
  return (
    <div
      style={{
        height: "300px",
      }}
    >
      <Doughnut options={options} data={data} />
    </div>
  );
};

export default DoughnutChart;
