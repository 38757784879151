import React, { useEffect, useState } from "react";
import { alpha, styled } from "@mui/material/styles";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  gridClasses,
} from "@mui/x-data-grid";
import { RenderCellExpand } from "./DataTableCellExpand";
import { BiCut } from "react-icons/bi";
import { BsImageFill } from "react-icons/bs";
import "../styles/featureData.scss";
import { Link } from "react-router-dom";

const DataTable = ({
  columnDetails,
  columnHeaders,
  rows,
  rowID,
  isLoading,
}) => {
  const [columns, setColumns] = useState([]);
  const gsBuckerURL = "//storage.googleapis.com/cloroximages";
  const ODD_OPACITY = 0.2;

  const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
      "&.Mui-selected": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity
        ),
        "&:hover, &.Mui-hovered": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity
          ),
          "@media (hover: none)": {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity
            ),
          },
        },
      },
    },
  }));

  useEffect(() => {
    const actualColumnHeaders = Object.keys(columnDetails);
    const dataTypes = Object.values(columnDetails)?.map(
      (column) => column.type
    );
    const widths = Object.values(columnDetails).map((column) => column.width);
    let tempColumns = [];

    for (let i = 0; i < actualColumnHeaders.length; i++) {
      const values = rows.map((keys) => keys[actualColumnHeaders[i]]);
      // const isValueString = values.every(value => isNaN(value));

      if (dataTypes[i] === "string") {
        // if (isValueString)
        // {
        console.log(actualColumnHeaders[i]);
        tempColumns.push({
          field: actualColumnHeaders[i],
          headerName: columnHeaders[i],
          type: "string",
          flex: 1,
          filterable: true,
          renderCell: RenderCellExpand,
        });
        // }
      } else {
        // if (!isValueString)
        // {
        tempColumns.push({
          field: actualColumnHeaders[i],
          headerName: columnHeaders[i],
          type: "number",
          flex: 1,
          filterable: true,
          renderCell: RenderCellExpand,
        });
        // }
      }
    }

    tempColumns.push({
      field: "Images",
      headerName: "Images",
      width: 110,
      renderCell: (params) => {
        console.log(params?.row, "jlkjljl");
        return (
          !(params?.row?.Photolink == null) && (
            <div className="w-100 d-flex justify-content-evenly align-content-center">
              <Link
                to={gsBuckerURL + params?.row?.Photolink}
                action="replace"
                target="_blank"
                rel="noopener noreferrer"
              >
                <BsImageFill style={{ fontSize: "20px" }} />
              </Link>
              <Link
                to={gsBuckerURL + params?.row?.cutoutlink}
                action="replace"
                target="_blank"
                rel="noopener noreferrer"
              >
                <BiCut style={{ fontSize: "20px" }} />
              </Link>
            </div>
          )
        );
      },
    });

    setColumns(tempColumns);
  }, [columnDetails, columnHeaders, rows]);

  function CustomGridToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport className="export" />
      </GridToolbarContainer>
    );
  }

  return (
    <div className="datagrid">
      <StripedDataGrid
        columns={columns}
        rows={rows}
        pageSize={50}
        density="compact"
        loading={isLoading ? isLoading : false}
        disableExtendRowFullWidth={true}
        disableSelectionOnClick={true}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
        components={{ Toolbar: CustomGridToolbar }}
        getRowId={(row) => row[rowID]}
      />
    </div>
  );
};

export default DataTable;
