import React, { useState } from 'react';
import { Map, Marker, GoogleApiWrapper, InfoWindow } from 'google-maps-react';
// import CustomMapDirectionsRenderer from './CustomMapDirectionsRenderer';
import '../styles/map.css';
import { useEffect } from 'react';
// /* global google */

const CustomMap = (props) => 
{
  const [mapCenter, setMapCenter] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState({});
  const [activeMarker, setActiveMarker] = useState({});
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);

  useEffect(() =>
  {
    if (props)
    {
      setMapCenter(props?.mapCenter);
      setMarkers(props?.markers);
    }

  }, [props]);

  const onMarkerClick = (props, marker) =>
  {
    setSelectedPlace(props);
    setActiveMarker(marker);
    setShowingInfoWindow(true);
  }

  const onMapClicked = () => 
  {
    if (showingInfoWindow) 
    {
      setActiveMarker(null);
      setShowingInfoWindow(false);
    }
  }

  return (
    <div style = {{ height: '100vh' }}>
      <Map 
        google = {props.google}
        style = {{ width: '100%', height: '100%', position: 'relative' }}
        className = {'map'}
        zoom = {5}
        // center = {mapCenter ? mapCenter: { lat: 0, lng: 0 }}
        center = {{ lat: 23.8859, lng: 45.0792 }}
        onClick = {onMapClicked}
      >
        {markers && markers?.map((marker, index) => (
          <Marker
            key = {index}
            name = {marker.storeName}
            position = {{ lat: marker.visitLatitude, lng: marker.visitLongitude }} 
            onClick = {onMarkerClick}
            // icon = {{ url: 'http://maps.google.com/mapfiles/kml/paddle/blu-blank.png', scaledSize: new google.maps.Size(50, 50) }}
            label = {{ text: `${index}`, color: 'white' }}
          />    
        ))}

        <InfoWindow marker = {activeMarker} visible = {showingInfoWindow}>
          <h1>{selectedPlace.name}</h1>
        </InfoWindow>

        {/* {markers && markers.length > 0 && (
          <CustomMapDirectionsRenderer
            places = {markers}
            travelMode = {google.maps.TravelMode.DRIVING}
          />
        )} */}
      </Map>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDeOS-wXw1aPwdjUj68vXAgRN0MxPU7HgA'
})(CustomMap);