import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import '../styles/components.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const MultiTuple = ({ loading, title, labels, datasets }) => 
{
  const [matches, setMatches] = useState(window.matchMedia("(max-width: 992px)").matches);

  const options = 
  {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
          pointStyle: 'circle'
        }
      },
      title: {
        display: true,
        text: title ? title : "",
        color: '#065886',
        padding: {
          top: 10,
          bottom: 20
        }
      },
      datalabels: {
        display: false
      },
       font: { size: "13",weight:'bolder' }
    }
  }

  useEffect(() => 
  {
    window
      .matchMedia("(max-width: 992px)")
      .addEventListener('change', e => setMatches(e.matches));
  }, []);

  const data = {
    labels: labels && labels.length > 0 ? labels : [],
    datasets: datasets && datasets.length > 0 ? datasets : []
  };


  return (
    <div className = "custom_graph">
      {loading ? (
        <div className = "col-12 text-center">
          <br />
          <div className = "spinner-border text-info" role = "status" style = {{ zIndex: '2' }}>
          </div>
          <span className = "text-info d-block">{title}</span>
        </div>
      ) : (
        <Bar options = {options} data = {data} height = {(title === "Secondary Display by Store" || matches) ? 300 : 200} />
      )}
    </div>
  );
}

export default MultiTuple;