import React from "react";
import { Dropdown, FormControl } from "react-bootstrap";
import "../styles/monitorAnalytics.scss";
import { useState } from "react";

const CustomMultiselect = ({
  filterValues,
  isSelectedAll,
  label,
  valuesToShow,
  setValuesToShow,
  setSelectedAll,
}) => {
  const [searchQuery, setSearchQuery] = useState("");

  const checkBoxFilterHandle = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      const checkedValue = valuesToShow.includes(value);

      if (!checkedValue) {
        const tempFilters = [...valuesToShow, value];
        setValuesToShow(tempFilters);

        if (tempFilters.length === filterValues.length) {
          setSelectedAll(true);
        }
      }
    } else {
      const uncheckedValue = valuesToShow.filter((element) => {
        return element !== value;
      });

      setValuesToShow(uncheckedValue);
      setSelectedAll(false);
    }
  };

  const selectAll = (e) => {
    if (!e.target.checked) {
      setValuesToShow([]);
    } else {
      let filterArray = [];

      filterArray.push(
        filterValues.map((value) => {
          return value;
        })
      );

      setValuesToShow(filterArray[0]);
    }

    setSelectedAll(e.target.checked);
  };
  const filteredOptions = filterValues.filter((option) =>
    option?.toLowerCase().includes(searchQuery?.toLowerCase())
  );
  return (
    <div className="custom_dropdowns">
      <div className="filter-dropdowns">
        <Dropdown
          className={`${
            !(valuesToShow.length > 0)
              ? "dropDown_disabled"
              : "dropdown_selected"
          }`}
        >
          <Dropdown.Toggle variant="secondary">{label}</Dropdown.Toggle>
          <Dropdown.Menu>
            <FormControl
            className="w-75 m-auto"
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <div className="p-3 d-flex flex-column" style={{ gap: "5px" }}>
              <div className="d-flex" style={{ gap: "0 8px" }}>
                <input
                  type="checkbox"
                  name="selectAll"
                  checked={isSelectedAll}
                  onChange={(e) => selectAll(e)}
                />
                <label htmlFor="selectAll">Select All</label>
              </div>

              {filteredOptions?.map((value, index) => (
                <div key={index} className="d-flex" style={{ gap: "0 10px" }}>
                  <input
                    type="checkbox"
                    value={value}
                    onChange={(e) => checkBoxFilterHandle(e)}
                    checked={valuesToShow.includes(value) ? true : false}
                  />
                  <label style={{ fontSize: "12px" }}>{value}</label>
                </div>
              ))}
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default CustomMultiselect;
