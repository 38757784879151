import React, { useEffect, useState } from "react";
import "leaflet/dist/leaflet.css";
import {
  Map,
  TileLayer,
  GeoJSON,
  MapContainer,
  Marker,
  Popup,
  Polygon,
} from "react-leaflet";
import { useMap } from "react-leaflet/hooks";
// import { countries } from "./geojson";
import randomcolor from "randomcolor";
import "../styles/map.css";
import "../styles/monitorAnalytics.scss";
import L from "leaflet";
import PolygonWithData from "./PolygonWithData";
import axios from "axios";

const center = [23.3829143, 42.9779255];

const getText = (value) => {
  return L.divIcon({ html: value });
};

const mainArray = [];
const CustomDataMap = ({ regionData, summaryPage }) => {
  const [polyGonCenter, setPolyGonCenter] = useState([]);

  const getStyle = (color) => {
    return {
      fill: color,
      weight: 0.3,
      opacity: 1,
      color: "purple",
      dashArray: "3",
      fillOpacity: 0.5,
    };
  };
console.log(regionData,'regionData')
  // useEffect(() => {

  //   const getRegion = async () => {
  //     await axios({
  //       method: "post",
  //       url: "/apiapi/on_shelf_availbility_region",
  //       data: JSON.stringify({
  //         category: [],
  //         month: '',
  //         city: [],
  //         customer: [],
  //         region: []
  //       })

  //     })
  //       .then((response) => {

  //         setRegions(response?.data?.data)
  //       })
  //       .catch((error) => {
  //         console.log("Filter Arrays Api: ", error);
  //       });
  //   }
  //   getRegion();
  // }, [])

  // useEffect(() => {

  //   const getPoints = () => {
  //       [
  //           [29.2004401, 36.0911349],
  // [29.3920638, 34.8386935],
  //   [28.9123322, 35.1463107],
  //   [28.295026, 34.9705294],
  //   [27.4598612, 35.6297091],
  //   [26.5003975, 36.3328341],
  //   [25.6121366, 37.0799044],
  //   [24.6972637, 37.3216036],
  //   [23.7555137, 38.4641818],
  //   [24.117006, 39.0794161],
  //   [24.637361, 39.5408419],
  //   [25.4535225, 39.3650607],
  //   [26.7361229, 39.1013888],
  //   [27.1083567, 38.8157443],
  //   [27.6157274, 38.4861544],
  //   [27.9073789, 38.002756],
  //   [28.3337144, 37.6292208],
  //   [28.4690101, 37.3216036],
  //   [28.4110474, 36.7942599],
  //   [28.488324, 36.4426974],
  //   [29.0276719, 36.2009982],
  //   [29.2004401, 36.0911349],
  //     ].forEach((item) => {
  //       function array_move(arr, old_index, new_index) {
  //         while (old_index < 0) {
  //           old_index += arr?.length;
  //         }
  //         while (new_index < 0) {
  //           new_index += arr?.length;
  //         }
  //         if (new_index >= arr?.length) {
  //           var k = new_index - arr?.length + 1;
  //           while (k--) {
  //             arr.push(undefined);
  //           }
  //         }
  //         arr.splice(new_index, 0, arr?.splice(old_index, 1)[0]);
  //         return arr;
  //       };

  //       mainArray.push((array_move(item, -1, -2)))
  //     })
  //   }
  //   getPoints()

  //   if (mainArray.length > 0) {
  //     setPolyGonCenter(L.polygon([
  //       mainArray
  //     ]).getBounds().getCenter())
  //   }

  // }, [countries])

  // const data = [...mainData];
  // data.push(mainArray);

  // setMainData([])

  const getCenter = (latLongs) => {
    return L.polygon([latLongs]).getBounds().getCenter();
  };

  console.log(mainArray, "rerter");
  return (
    <div
      className="my_grap"
      style={{ height: "400px", width: "100%", margin: "auto" }}
    >
      <MapContainer
        center={center}
        zoom={summaryPage ? 5 : 6}
        id="mapid"
        style={{ height: "100%", width: "100%" }}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {regionData?.length > 0 &&
          regionData?.map((region) => (
            <Polygon color={region?.color} positions={region?.latlong}>
              <Marker
                position={getCenter(region?.latlong)}
                icon={getText(region?.result)}
              />
            </Polygon>
          ))}

        {/* <PolygonWithData text="MyText" coords={newcountries} /> */}
        {/* <GeoJSON key="my-geojson" data={newcountries} style={style}>
          <Marker position={polyGonCenter} icon={text} />
        </GeoJSON> */}
      </MapContainer>
    </div>
  );
};
export default CustomDataMap;
