import React, { useState, useEffect } from "react";
import { Alert, Dropdown } from "react-bootstrap";
import axios from "axios";
import { BiReset } from "react-icons/bi";
import LineChart from "../../components/LineChart";
import StackBarChart from "../../components/StackBarChart";
import "../../styles/featureData.scss";
import LogUserActivity from "../../components/LogUserActivity";

const CloroxAndCompetitionData = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [alertError, setAlertError] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [logId, setLogId] = useState(null);
  /* STACK BAR CHART VARIABLES */
  const [categroyStackChartData, setCategroyStackChartData] = useState([]);
  const [customerStackChartData, setCustomerStackChartData] = useState([]);

  /* LINE CHART VARIABLES */
  const [isLineGraphLoading, setIsLineGraphLoading] = useState(false);
  const [lineGraphData, setLineGraphData] = useState("");

  /* FILTER VARIABLES */

  const [yearByCategorySelectedAll, setYearByCategorySelectedAll] =
    useState(false);
  const [yearByCategoryToShow, setYearByCategoryToShow] = useState([]);
  const [weekByCategorySelectedAll, setWeekByCategorySelectedAll] =
    useState(false);
  const [weekByCategoryToShow, setWeekByCategoryToShow] = useState([]);

  const [yearFilters, setYearFilters] = useState([]);
  const [weekFilters, setWeekFilters] = useState([]);
  const [categoryFilters, setCategoryFilters] = useState([]);
  const [customerFilters, setCustomerFilters] = useState([]);
  const [channelFilters, setChannelFilters] = useState([]);
  const [competitionFilters, setCompetitionFilters] = useState([]);
  const [categorySelectedAll, setCategorySelectedAll] = useState(false);
  const [barGraphCategorySelectedAll, setBarGraphCategorySelectedAll] =
    useState(false);
  const [competitionSelectedAll, setCompetitionSelectedAll] = useState(false);
  const [yearSelectedAll, setYearSelectedAll] = useState(false);
  const [customerSelectedAll, setCustomerSelectedAll] = useState(false);
  const [channelSelectedAll, setChannelSelectedAll] = useState(false);
  const [weekSelectedAll, setWeekSelectedAll] = useState(false);

  /* FILTER SELECTED VARIABLES  */
  const [weekSelectedFilter, setWeekSelectedFilter] = useState(0);
  const [yearSelectedFilter, setYearSelectedFilter] = useState("");
  const [categorySelectedFilter, setCategorySelectedFilter] = useState("");
  const [customerSelectedFilter, setCustomerSelectedFilter] = useState("");
  const [channelSelectedFilter, setChannelSelectedFilter] = useState("");

  /* LINE GRAPH FILTER SELECTED VARIABLES */
  const [categoriesToShow, setCategoriesToShow] = useState([]);
  const [barGraphCategoriesToShow, setBarGraphCategoriesToShow] = useState([]);
  const [customersToShow, setCustomersToShow] = useState([]);
  const [channelsToShow, setChannelsToShow] = useState([]);
  const [yearsToShow, setYearsToShow] = useState([]);
  const [weeksToShow, setWeeksToShow] = useState([]);
  const [competitionsToShow, setCompetitionsToShow] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    setAlertError(null);

    const fetchData = async () => {
      axios({
        method: "get",
        url: "/api/latest_week",
      })
        .then((response) => {
          setWeekSelectedFilter(response.data.week);
        })
        .catch((error) => {
          console.log("Latest Week Api: ", error);
          setAlertError("Something went wrong. Please try again.");
        });

      await axios({
        method: "post",
        url: "/api/filtervalues",
        headers: { "Content-Type": "application/json" },
        data: { category: categoriesToShow },
        timeout: 180000,
      })
        .then((response) => {
          setIsLoading(false);

          const responseData = response.data;

          setCategoryFilters(responseData.Category);
          setCustomerFilters(responseData.Customer);
          setWeekFilters(responseData.Week.map(String));
          setYearFilters(responseData.fiscalYear);
          setChannelFilters(responseData.channel);
          setCompetitionFilters(responseData.competition);
        })
        .catch((error) => {
          console.log("Filter Values Api: ", error);
          setIsLoading(false);
          setAlertError("Something went wrong. Please try again.");
        });
    };
    LogUserActivity(
      "Clorox And CompetitionData Activity Started",
      props?.currentUser?.user,
      setLogId,
      setAlertError
    );
    fetchData();

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    if (categoriesToShow && categoriesToShow?.length > 0) {
      setIsLineGraphLoading(true);
      setAlertError(null);

      const getLineChart = async () => {
        await axios({
          method: "post",
          url: "/api/line_graph",
          headers: { "Content-Type": "application/json" },
          data: {
            fiscalYear: yearByCategoryToShow,
            week: weekByCategoryToShow,
            category: categoriesToShow,
          },
        })
          .then((response) => {
            setIsLineGraphLoading(false);
            setLineGraphData(response.data);
          })
          .catch((error) => {
            console.log("Line Graph Api: ", error);
            setIsLineGraphLoading(false);
            setAlertError("Something went wrong. Please try again.");
          });
      };

      getLineChart();
    }
  }, [categoriesToShow, yearByCategoryToShow, weekByCategoryToShow]);

  useEffect(() => {
    if (categoryFilters) {
      let filterArray = [];

      filterArray.push(
        categoryFilters.map((category) => {
          return category;
        })
      );

      setCategoriesToShow(filterArray[0]);
      setBarGraphCategoriesToShow(filterArray[0]);
      setCategorySelectedAll(true);
      setBarGraphCategorySelectedAll(true);
    }

    if (competitionFilters) {
      let filterArray = [];

      filterArray.push(
        competitionFilters.map((competition) => {
          return competition;
        })
      );

      setCompetitionsToShow(filterArray[0]);
      setCompetitionSelectedAll(true);
    }
    if (yearFilters) {
      let filterArray = [];

      filterArray.push(
        yearFilters.map((year) => {
          return year;
        })
      );

      setYearsToShow(filterArray[0]);
      setYearSelectedAll(true);
    }
    if (weekFilters) {
      let filterArray = [];

      filterArray.push(
        weekFilters.map((week) => {
          return week;
        })
      );

      setWeeksToShow(filterArray[0]);
      setWeekSelectedAll(true);
    }
    if (customerFilters) {
      let filterArray = [];

      filterArray.push(
        customerFilters.map((week) => {
          return week;
        })
      );

      setCustomersToShow(filterArray[0]);
      setCustomerSelectedAll(true);
    }
    if (channelFilters) {
      let filterArray = [];

      filterArray.push(
        channelFilters.map((week) => {
          return week;
        })
      );

      setChannelsToShow(filterArray[0]);
      setChannelSelectedAll(true);
    }
  }, [
    categoryFilters,
    competitionFilters,
    yearFilters,
    weekFilters,
    customerFilters,
    channelFilters,
  ]);

  useEffect(() => {
    setIsLoading(true);
    setAlertError(null);

    const getCategoryStackChart = async () => {
      await axios({
        method: "post",
        url: "/api/StackedChartDataCatgory",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify({
          category: barGraphCategoriesToShow,
          customer: customersToShow,
          week: weeksToShow,
          channel: channelsToShow,
          fiscalYear: yearsToShow,
        }),
      })
        .then((response) => {
          setIsLoading(false);
          setCategroyStackChartData(response.data);
        })
        .catch((error) => {
          console.log("Category Stack Chart Api: ", error);
          setIsLoading(false);
          setAlertError("Something went wrong. Please try again.");
        });
    };

    const getCustomerStackChart = async () => {
      await axios({
        method: "post",
        url: "/api/StackedChartDataCustomer",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify({
          category: barGraphCategoriesToShow,
          customer: customersToShow,
          week: weeksToShow,
          channel: channelsToShow,
          fiscalYear: yearsToShow,
        }),
      })
        .then((response) => {
          setIsLoading(false);
          setCustomerStackChartData(response.data);
        })
        .catch((error) => {
          console.log("Customer Stack Chart Api: ", error);
          setIsLoading(false);
          setAlertError("Something went wrong. Please try again.");
        });
    };
    if (
      barGraphCategoriesToShow &&
      customersToShow &&
      weeksToShow &&
      channelsToShow &&
      yearsToShow &&
      !filtersApplied
    ) {
      getCustomerStackChart();
      getCategoryStackChart();
    }
  }, []);

  const applyfilters = () => {
    setFiltersApplied(true);
    setIsLoading(true);
    setAlertError(null);

    const getCategoryStackChart = async () => {
      await axios({
        method: "post",
        url: "/api/StackedChartDataCatgory",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify({
          category: barGraphCategoriesToShow,
          customer: customersToShow,
          week: weeksToShow,
          channel: channelsToShow,
          fiscalYear: yearsToShow,
        }),
      })
        .then((response) => {
          setIsLoading(false);
          setCategroyStackChartData(response.data);
        })
        .catch((error) => {
          console.log("Category Stack Chart Api: ", error);
          setIsLoading(false);
          setAlertError("Something went wrong. Please try again.");
        });
    };

    const getCustomerStackChart = async () => {
      await axios({
        method: "post",
        url: "/api/StackedChartDataCustomer",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify({
          category: barGraphCategoriesToShow,
          customer: customersToShow,
          week: weeksToShow,
          channel: channelsToShow,
          fiscalYear: yearsToShow,
        }),
      })
        .then((response) => {
          setIsLoading(false);
          setCustomerStackChartData(response.data);
        })
        .catch((error) => {
          console.log("Customer Stack Chart Api: ", error);
          setIsLoading(false);
          setAlertError("Something went wrong. Please try again.");
        });
    };
    if (
      barGraphCategoriesToShow &&
      customersToShow &&
      weeksToShow &&
      channelsToShow &&
      yearsToShow
    ) {
      getCustomerStackChart();
      getCategoryStackChart();
    }
  };

  const handleReset = (flag) => {
    if (flag === "year") {
      setYearSelectedFilter("");
    } else if (flag === "week") {
      setWeekSelectedFilter(0);
    } else if (flag === "category") {
      setCategorySelectedFilter("");
    } else if (flag === "customer") {
      setCustomerSelectedFilter("");
    } else if (flag === "channel") {
      setChannelSelectedFilter("");
    }
  };

  const selectAll = (e, flag) => {
    if (flag === "weeksByCategory") {
      if (!e.target.checked) {
        setWeekByCategoryToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          weekFilters.map((week) => {
            return week;
          })
        );

        setWeekByCategoryToShow(filterArray[0]);
      }

      setWeekByCategorySelectedAll(e.target.checked);
    }
    if (flag === "yearByCategory") {
      if (!e.target.checked) {
        setYearByCategoryToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          yearFilters.map((week) => {
            return week;
          })
        );

        setYearByCategoryToShow(filterArray[0]);
      }

      setYearByCategorySelectedAll(e.target.checked);
    }

    if (flag === "Categories") {
      if (!e.target.checked) {
        setCategoriesToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          categoryFilters.map((category) => {
            return category;
          })
        );

        setCategoriesToShow(filterArray[0]);
      }

      setCategorySelectedAll(e.target.checked);
    }
    if (flag === "barGraphCategories") {
      if (!e.target.checked) {
        setBarGraphCategoriesToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          categoryFilters.map((category) => {
            return category;
          })
        );

        setBarGraphCategoriesToShow(filterArray[0]);
      }

      setBarGraphCategorySelectedAll(e.target.checked);
    }
    if (flag === "years") {
      if (!e.target.checked) {
        setYearsToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          yearFilters.map((year) => {
            return year;
          })
        );

        setYearsToShow(filterArray[0]);
      }

      setYearSelectedAll(e.target.checked);
    }
    if (flag === "weeks") {
      if (!e.target.checked) {
        setWeeksToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          weekFilters.map((week) => {
            return week;
          })
        );

        setWeeksToShow(filterArray[0]);
      }

      setWeekSelectedAll(e.target.checked);
    }
    if (flag === "customers") {
      if (!e.target.checked) {
        setCustomersToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          customerFilters.map((customer) => {
            return customer;
          })
        );

        setCustomersToShow(filterArray[0]);
      }

      setCustomerSelectedAll(e.target.checked);
    }
    if (flag === "channels") {
      if (!e.target.checked) {
        setChannelsToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          channelFilters.map((channel) => {
            return channel;
          })
        );

        setChannelsToShow(filterArray[0]);
      }

      setChannelSelectedAll(e.target.checked);
    }
    if (flag === "Competitions") {
      if (!e.target.checked) {
        setCompetitionsToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          competitionFilters.map((competition) => {
            return competition;
          })
        );

        setCompetitionsToShow(filterArray[0]);
      }

      setCompetitionSelectedAll(e.target.checked);
    }
  };

  const checkBoxFilterHandle = (e, flag) => {
    const { checked, value } = e.target;

    if (flag === "category") {
      if (checked) {
        const checkedValue = categoriesToShow.includes(value);

        if (!checkedValue) {
          const tempCategoryFilters = [...categoriesToShow, value];
          setCategoriesToShow(tempCategoryFilters);

          if (tempCategoryFilters.length === categoryFilters.length) {
            setCategorySelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = categoriesToShow.filter((element) => {
          return element !== value;
        });

        setCategoriesToShow(uncheckedValue);
        setCategorySelectedAll(false);
      }
    }
    if (flag === "barGraphCategories") {
      if (checked) {
        const checkedValue = barGraphCategoriesToShow.includes(value);

        if (!checkedValue) {
          const tempCategoryFilters = [...barGraphCategoriesToShow, value];
          setBarGraphCategoriesToShow(tempCategoryFilters);

          if (tempCategoryFilters.length === categoryFilters.length) {
            setCategorySelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = barGraphCategoriesToShow.filter((element) => {
          return element !== value;
        });

        setBarGraphCategoriesToShow(uncheckedValue);
        setBarGraphCategorySelectedAll(false);
      }
    }
    if (flag === "competition") {
      if (checked) {
        const checkedValue = competitionsToShow.includes(value);

        if (!checkedValue) {
          const tempCompetitionFilters = [...competitionsToShow, value];
          setCompetitionsToShow(tempCompetitionFilters);

          if (tempCompetitionFilters.length === competitionFilters.length) {
            setCompetitionSelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = competitionsToShow.filter((element) => {
          return element !== value;
        });

        setCompetitionsToShow(uncheckedValue);
        setCompetitionSelectedAll(false);
      }
    }
    if (flag === "year") {
      if (checked) {
        const checkedValue = yearsToShow.includes(value);

        if (!checkedValue) {
          const tempyearFilters = [...yearsToShow, value];
          setYearsToShow(tempyearFilters);

          if (tempyearFilters.length === yearFilters.length) {
            setYearSelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = yearsToShow.filter((element) => {
          return element !== value;
        });

        setYearsToShow(uncheckedValue);
        setYearSelectedAll(false);
      }
    }
    if (flag === "week") {
      // const checkedValue = weeksToShow.includes(value);
      // console.log(checkedValue)
      if (checked) {
        const checkedValue = weeksToShow.includes(value);
        console.log(value);
        if (!checkedValue) {
          const tempweekFilters = [...weeksToShow, value];
          setWeeksToShow(tempweekFilters);

          if (tempweekFilters.length === weekFilters.length) {
            setWeekSelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = weeksToShow.filter((element) => {
          return element !== value;
        });

        setWeeksToShow(uncheckedValue);
        setWeekSelectedAll(false);
      }
    }
    if (flag === "yearByCategory") {
      if (checked) {
        const checkedValue = yearByCategoryToShow.includes(value);

        if (!checkedValue) {
          const tempyearFilters = [...yearByCategoryToShow, value];
          setYearByCategoryToShow(tempyearFilters);

          if (tempyearFilters.length === yearFilters.length) {
            setYearByCategorySelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = yearByCategoryToShow.filter((element) => {
          return element !== value;
        });

        setYearByCategoryToShow(uncheckedValue);
        setYearByCategorySelectedAll(false);
      }
    }
    if (flag === "weeksByCategory") {
      // const checkedValue = weeksToShow.includes(value);
      // console.log(checkedValue)
      if (checked) {
        const checkedValue = weekByCategoryToShow.includes(value);
        console.log(value);
        if (!checkedValue) {
          const tempweekFilters = [...weekByCategoryToShow, value];
          setWeekByCategoryToShow(tempweekFilters);

          if (tempweekFilters.length === weekFilters.length) {
            setWeekByCategorySelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = weekByCategoryToShow.filter((element) => {
          return element !== value;
        });

        setWeekByCategoryToShow(uncheckedValue);
        setWeekByCategorySelectedAll(false);
      }
    }

    if (flag === "customer") {
      // const checkedValue = weeksToShow.includes(value);
      // console.log(checkedValue)
      if (checked) {
        const checkedValue = customersToShow.includes(value);
        console.log(value);
        if (!checkedValue) {
          const tempweekFilters = [...customersToShow, value];
          setCustomersToShow(tempweekFilters);

          if (tempweekFilters.length === weekFilters.length) {
            setCustomerSelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = customersToShow.filter((element) => {
          return element !== value;
        });

        setCustomersToShow(uncheckedValue);
        setCustomerSelectedAll(false);
      }
    }

    if (flag === "channel") {
      // const checkedValue = weeksToShow.includes(value);
      // console.log(checkedValue)
      if (checked) {
        const checkedValue = channelsToShow.includes(value);
        console.log(value);
        if (!checkedValue) {
          const tempweekFilters = [...channelsToShow, value];
          setChannelsToShow(tempweekFilters);

          if (tempweekFilters.length === weekFilters.length) {
            setChannelSelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = channelsToShow.filter((element) => {
          return element !== value;
        });

        setChannelsToShow(uncheckedValue);
        setChannelSelectedAll(false);
      }
    }
  };

  return (
    <div className="container-fluid feature_data position-relative">
      <h2 className="heading_sm_center">Clorox and Competition Data</h2>

      {isLoading ? (
        <div className="col-md-12 d-flex justify-content-center loader">
          <br />
          <div
            className="spinner-border text-info"
            role="status"
            style={{ zIndex: "2" }}
          ></div>
          <div className="loader-message">Loading...</div>
        </div>
      ) : alertError ? (
        <Alert variant="danger">{alertError}</Alert>
      ) : (
        <div className="row content_main_row">
          <div className="filters col-md-12">
            <div className="row w-100 filters_row justify-content-between">
              <div className="col py-0">
                <div
                  className="filters_right w-100 justify-content-center"
                  style={{ gap: "5px" }}
                >
                  <div className="filter-dropdowns">
                    <BiReset onClick={() => handleReset("year")} />
                    <Dropdown
                      className={`${
                        !(yearsToShow.length > 0)
                          ? "dropDown_disabled"
                          : "dropdown_selected"
                      }`}
                    >
                      <Dropdown.Toggle variant="secondary">
                        Year
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div
                          className="p-3 d-flex flex-column"
                          style={{ gap: "5px" }}
                        >
                          <div className="d-flex" style={{ gap: "0 8px" }}>
                            <input
                              type="checkbox"
                              name="selectAll"
                              checked={yearSelectedAll}
                              onChange={(e) => selectAll(e, "years")}
                            />
                            <label htmlFor="selectAll">Select All</label>
                          </div>

                          {yearFilters?.map((year, index) => (
                            <div
                              key={index}
                              className="d-flex"
                              style={{ gap: "0 10px" }}
                            >
                              <input
                                type="checkbox"
                                value={year}
                                onChange={(e) =>
                                  checkBoxFilterHandle(e, "year")
                                }
                                checked={
                                  yearsToShow.includes(year) ? true : false
                                }
                              />
                              <label style={{ fontSize: "12px" }}>{year}</label>
                            </div>
                          ))}
                        </div>
                      </Dropdown.Menu>
                      {/* <Dropdown.Menu>
                                                {yearFilters?.map((filter, index) =>
                                                    <Dropdown.Item key={index} onClick={(event) => setYearSelectedFilter(event.target.text)}>{filter}</Dropdown.Item>
                                                )}
                                            // </Dropdown.Menu> */}
                    </Dropdown>
                  </div>

                  <div className="filter-dropdowns">
                    <BiReset onClick={() => handleReset("week")} />
                    <Dropdown
                      className={`${
                        !(weeksToShow.length > 0)
                          ? "dropDown_disabled"
                          : "dropdown_selected"
                      }`}
                    >
                      <Dropdown.Toggle variant="secondary">
                        Week
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div
                          className="p-3 d-flex flex-column"
                          style={{ gap: "5px" }}
                        >
                          <div className="d-flex" style={{ gap: "0 8px" }}>
                            <input
                              type="checkbox"
                              name="selectAll"
                              checked={weekSelectedAll}
                              onChange={(e) => selectAll(e, "weeks")}
                            />
                            <label htmlFor="selectAll">Select All</label>
                          </div>

                          {weekFilters?.map((week, index) => (
                            <div
                              key={index}
                              className="d-flex"
                              style={{ gap: "0 10px" }}
                            >
                              <input
                                type="checkbox"
                                value={week}
                                onChange={(e) =>
                                  checkBoxFilterHandle(e, "week")
                                }
                                checked={
                                  weeksToShow.includes(week) ? true : false
                                }
                              />
                              <label style={{ fontSize: "12px" }}>{week}</label>
                            </div>
                          ))}
                        </div>
                      </Dropdown.Menu>
                      {/* <Dropdown.Menu>
                                                {weekFilters?.map((filter, index) =>
                                                    <Dropdown.Item key={index} onClick={(event) => setWeekSelectedFilter(event.target.text)}>{filter}</Dropdown.Item>
                                                )}
                                            </Dropdown.Menu> */}
                    </Dropdown>
                  </div>
                </div>
              </div>

              <div className="col-md-8 col-xl-7">
                <div
                  className="filters_right w-100 justify-content-center"
                  style={{ gap: "5px" }}
                >
                  <div>
                    <BiReset
                      onClick={() => handleReset("barGraphCategories")}
                    />
                    <Dropdown
                      className={`${
                        !(barGraphCategoriesToShow.length > 0)
                          ? "dropDown_disabled"
                          : "dropdown_selected"
                      }`}
                    >
                      <Dropdown.Toggle variant="secondary">
                        Category
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <div
                          className="p-3 d-flex flex-column"
                          style={{ gap: "5px" }}
                        >
                          <div className="d-flex" style={{ gap: "0 8px" }}>
                            <input
                              type="checkbox"
                              name="selectAll"
                              checked={barGraphCategorySelectedAll}
                              onChange={(e) =>
                                selectAll(e, "barGraphCategories")
                              }
                            />
                            <label htmlFor="selectAll">Select All</label>
                          </div>

                          {categoryFilters?.map((category, index) => (
                            <div
                              key={index}
                              className="d-flex"
                              style={{ gap: "0 10px" }}
                            >
                              <input
                                type="checkbox"
                                value={category}
                                onChange={(e) =>
                                  checkBoxFilterHandle(e, "barGraphCategories")
                                }
                                checked={
                                  barGraphCategoriesToShow.includes(category)
                                    ? true
                                    : false
                                }
                              />
                              <label style={{ fontSize: "12px" }}>
                                {category}
                              </label>
                            </div>
                          ))}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <div>
                    <BiReset onClick={() => handleReset("customer")} />
                    <Dropdown
                      className={`${
                        !(customersToShow.length > 0)
                          ? "dropDown_disabled"
                          : "dropdown_selected"
                      }`}
                    >
                      <Dropdown.Toggle variant="secondary">
                        Customer
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div
                          className="p-3 d-flex flex-column"
                          style={{ gap: "5px" }}
                        >
                          <div className="d-flex" style={{ gap: "0 8px" }}>
                            <input
                              type="checkbox"
                              name="selectAll"
                              checked={customerSelectedAll}
                              onChange={(e) => selectAll(e, "customers")}
                            />
                            <label htmlFor="selectAll">Select All</label>
                          </div>

                          {customerFilters?.map((customer, index) => (
                            <div
                              key={index}
                              className="d-flex"
                              style={{ gap: "0 10px" }}
                            >
                              <input
                                type="checkbox"
                                value={customer}
                                onChange={(e) =>
                                  checkBoxFilterHandle(e, "customer")
                                }
                                checked={
                                  customersToShow.includes(customer)
                                    ? true
                                    : false
                                }
                              />
                              <label style={{ fontSize: "12px" }}>
                                {customer}
                              </label>
                            </div>
                          ))}
                        </div>
                      </Dropdown.Menu>
                      {/* <Dropdown.Menu>
                                                {customerFilters?.map((filter, index) =>
                                                    <Dropdown.Item key={index} onClick={(event) => setCustomerSelectedFilter(event.target.text)}>{filter}</Dropdown.Item>
                                                )}
                                            </Dropdown.Menu> */}
                    </Dropdown>
                  </div>

                  <div>
                    <BiReset onClick={() => handleReset("channel")} />
                    <Dropdown
                      className={`${
                        !(channelsToShow.length > 0)
                          ? "dropDown_disabled"
                          : "dropdown_selected"
                      }`}
                    >
                      <Dropdown.Toggle variant="secondary">
                        Channel
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <div
                          className="p-3 d-flex flex-column"
                          style={{ gap: "5px" }}
                        >
                          <div className="d-flex" style={{ gap: "0 8px" }}>
                            <input
                              type="checkbox"
                              name="selectAll"
                              checked={channelSelectedAll}
                              onChange={(e) => selectAll(e, "channels")}
                            />
                            <label htmlFor="selectAll">Select All</label>
                          </div>

                          {channelFilters?.map((channel, index) => (
                            <div
                              key={index}
                              className="d-flex"
                              style={{ gap: "0 10px" }}
                            >
                              <input
                                type="checkbox"
                                value={channel}
                                onChange={(e) =>
                                  checkBoxFilterHandle(e, "channel")
                                }
                                checked={
                                  channelsToShow.includes(channel)
                                    ? true
                                    : false
                                }
                              />
                              <label style={{ fontSize: "12px" }}>
                                {channel}
                              </label>
                            </div>
                          ))}
                        </div>
                      </Dropdown.Menu>
                      {/* <Dropdown.Menu>
                                                {channelFilters?.map((filter, index) =>
                                                    <Dropdown.Item key={index} onClick={(event) => setChannelSelectedFilter(event.target.text)}>{filter}</Dropdown.Item>
                                                )}
                                            </Dropdown.Menu> */}
                    </Dropdown>
                  </div>
                  <button className="btn btn-success" onClick={applyfilters}>
                    Filter
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-5">
            <div className="row w-100 flex-column align-items-center">
              <div className="col-md-12 my_graph">
                <StackBarChart
                  chartFor="Customer"
                  dataForChar={customerStackChartData?.data}
                  chartLabels={customerStackChartData?.master}
                  colors={customerStackChartData?.color}
                />
              </div>
            </div>
          </div>

          <div className="col-md-5">
            <div className="row w-100 flex-column align-items-center">
              <div className="col-md-12 my_graph">
                <StackBarChart
                  chartFor="Category"
                  dataForChar={categroyStackChartData?.data}
                  chartLabels={categroyStackChartData?.master}
                  colors={categroyStackChartData?.color}
                />
              </div>
            </div>
          </div>

          <hr className="mb-2" />

          <div className="col-md-12">
            <h3 className="mb-4">By Week By Category By Customer Trend</h3>

            <div className="row w-100 justify-content-center filters_right">
              <div className="col-5 col-md-2 d-flex justify-content-between">
                <div className="filter-dropdowns">
                  <Dropdown
                    className={`${
                      !(yearByCategoryToShow.length > 0)
                        ? "dropDown_disabled"
                        : "dropdown_selected"
                    }`}
                  >
                    <Dropdown.Toggle variant="secondary">Year</Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div
                        className="p-3 d-flex flex-column"
                        style={{ gap: "5px" }}
                      >
                        <div className="d-flex" style={{ gap: "0 8px" }}>
                          <input
                            type="checkbox"
                            name="selectAll"
                            checked={yearByCategorySelectedAll}
                            onChange={(e) => selectAll(e, "yearByCategory")}
                          />
                          <label htmlFor="selectAll">Select All</label>
                        </div>

                        {yearFilters?.map((year, index) => (
                          <div
                            key={index}
                            className="d-flex"
                            style={{ gap: "0 10px" }}
                          >
                            <input
                              type="checkbox"
                              value={year}
                              onChange={(e) =>
                                checkBoxFilterHandle(e, "yearByCategory")
                              }
                              checked={
                                yearByCategoryToShow.includes(year)
                                  ? true
                                  : false
                              }
                            />
                            <label style={{ fontSize: "12px" }}>{year}</label>
                          </div>
                        ))}
                      </div>
                    </Dropdown.Menu>
                    {/* <Dropdown.Menu>
                                                {yearFilters?.map((filter, index) =>
                                                    <Dropdown.Item key={index} onClick={(event) => setYearSelectedFilter(event.target.text)}>{filter}</Dropdown.Item>
                                                )}
                                            // </Dropdown.Menu> */}
                  </Dropdown>
                </div>
              </div>
              <div className="col-5 col-md-2 d-flex justify-content-between">
                <div className="filter-dropdowns">
                  <Dropdown
                    className={`${
                      !(weekByCategoryToShow.length > 0)
                        ? "dropDown_disabled"
                        : "dropdown_selected"
                    }`}
                  >
                    <Dropdown.Toggle variant="secondary">Week</Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div
                        className="p-3 d-flex flex-column"
                        style={{ gap: "5px" }}
                      >
                        <div className="d-flex" style={{ gap: "0 8px" }}>
                          <input
                            type="checkbox"
                            name="selectAll"
                            checked={weekByCategorySelectedAll}
                            onChange={(e) => selectAll(e, "weeksByCategory")}
                          />
                          <label htmlFor="selectAll">Select All</label>
                        </div>

                        {weekFilters?.map((week, index) => (
                          <div
                            key={index}
                            className="d-flex"
                            style={{ gap: "0 10px" }}
                          >
                            <input
                              type="checkbox"
                              value={week}
                              onChange={(e) =>
                                checkBoxFilterHandle(e, "weeksByCategory")
                              }
                              checked={
                                weekByCategoryToShow.includes(week)
                                  ? true
                                  : false
                              }
                            />
                            <label style={{ fontSize: "12px" }}>{week}</label>
                          </div>
                        ))}
                      </div>
                    </Dropdown.Menu>
                    {/* <Dropdown.Menu>
                                                {weekFilters?.map((filter, index) =>
                                                    <Dropdown.Item key={index} onClick={(event) => setWeekSelectedFilter(event.target.text)}>{filter}</Dropdown.Item>
                                                )}
                                            </Dropdown.Menu> */}
                  </Dropdown>
                </div>
              </div>
              <div className="col-5 col-md-2 d-flex justify-content-between">
                <div className="filter-dropdowns">
                  <Dropdown
                    className={`${
                      categoriesToShow.length !== 0
                        ? "dropdown_selected"
                        : "dropDown_disabled"
                    }`}
                  >
                    <Dropdown.Toggle variant="secondary">
                      Categories
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <div
                        className="p-3 d-flex flex-column"
                        style={{ gap: "5px" }}
                      >
                        <div className="d-flex" style={{ gap: "0 8px" }}>
                          <input
                            type="checkbox"
                            name="selectAll"
                            checked={categorySelectedAll}
                            onChange={(e) => selectAll(e, "Categories")}
                          />
                          <label htmlFor="selectAll">Select All</label>
                        </div>

                        {categoryFilters?.map((category, index) => (
                          <div
                            key={index}
                            className="d-flex"
                            style={{ gap: "0 10px" }}
                          >
                            <input
                              type="checkbox"
                              value={category}
                              onChange={(e) =>
                                checkBoxFilterHandle(e, "category")
                              }
                              checked={
                                categoriesToShow.includes(category)
                                  ? true
                                  : false
                              }
                            />
                            <label style={{ fontSize: "12px" }}>
                              {category}
                            </label>
                          </div>
                        ))}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>

                  {/* <Dropdown className={`${competitionsToShow.length !== 0 ? 'dropdown_selected' : 'dropDown_disabled'}`}>
                                    <Dropdown.Toggle variant="secondary">
                                        Competitions
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <div className="p-3 d-flex flex-column" style={{ gap: '5px' }}>
                                            <div className="d-flex" style={{ gap: '0 8px' }}>
                                                <input
                                                    type="checkbox"
                                                    name="selectAll"
                                                    checked={competitionSelectedAll}
                                                    onChange={(e) => selectAll(e, 'Competitions')}
                                                />
                                                <label htmlFor="selectAll">Select All</label>
                                            </div>

                                            {competitionFilters?.map((competition, index) =>
                                                <div key={index} className="d-flex" style={{ gap: '0 10px' }}>
                                                    <input
                                                        type="checkbox"
                                                        value={competition}
                                                        onChange={(e) => checkBoxFilterHandle(e, 'competition')}
                                                        checked={(competitionsToShow.includes(competition)) ? true : false}
                                                    />
                                                    <label style={{ fontSize: '12px' }}>{competition}</label>
                                                </div>
                                            )}
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown> */}
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div
                className={`col-md-8 my_graph ${
                  !isLineGraphLoading &&
                  (!lineGraphData || lineGraphData?.weeks?.length === 0)
                    ? "d-flex align-items-center"
                    : ""
                }`}
              >
                {lineGraphData?.weeks?.length > 0 ? (
                  <LineChart
                    loading={isLineGraphLoading}
                    lineGraphData={lineGraphData?.data}
                    weeks={lineGraphData?.weeks}
                    colors={lineGraphData?.color}
                    valuesToShow={competitionsToShow}
                    customHeight={450}
                  />
                ) : (
                  <span>No data found.</span>
                )}
              </div>
            </div>
          </div>
          <br />
        </div>
      )}
    </div>
  );
};

export default CloroxAndCompetitionData;
