import axios from "axios";

const LogUserActivity = async (page, username, setLogId, setAlertError) => {
  try {
    const response = await axios.post("/api/LogUserActivity", {
      page,
      username,
    });

    console.log(response, "activity");
    setLogId(response?.data?.LogId);
  } catch (error) {
    console.error("Log User Activity Error: ", error);
    setAlertError("Something went wrong");
  }
};

export default LogUserActivity;
