import React, { useEffect, useState } from "react";
import CustomMap from "../../components/CustomMap";
import { Table, Alert } from "react-bootstrap";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { BiReset } from "react-icons/bi";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import moment from "moment";
import axios from "axios";
import "../../styles/monitorAnalytics.scss";
import LogUserActivity from "../../components/LogUserActivity";

const MonitorRoute = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [alertError, setAlertError] = useState(null);
 const [logId, setLogId] = useState(null);
  /* MAP VARIABLES */
  const [mapCenter, setMapCenter] = useState(null);
  const [markers, setMarkers] = useState([]);

  /* FILTER VARIABLES */
  const [monitorFilters, setMonitorFilters] = useState([]);
  const [isFilterable, setIsFilterable] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  /* SELECTED FILTER VARIABLES */
  const [tempSelectedMonitor, setTempSelectedMonitor] = useState("");
  const [selectedMonitor, setSelectedMonitor] = useState("");
  const [tempSelectedStartDate, setTempSelectedStartDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [selectedStartDate, setSelectedStartDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [tempSelectedEndDate, setTempSelectedEndDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);

  const ITEM_HEIGHT = 50;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5,
        width: 200,
      },
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      setAlertError(null);

      axios({
        method: "get",
        url: "/api/Monitor_filter_values_for_monitor_route",
      })
        .then((response) => {
          if (response.status === 200) {
            const responseData = response.data;
            setMonitorFilters(responseData.username);
            setTempSelectedMonitor(responseData.username[0]);
            setSelectedMonitor(responseData.username[0]);
          }
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("Something went wrong. Please try again.");
        });
    };
LogUserActivity("Monitor Route Activity Started", props?.currentUser?.user, setLogId, setAlertError);
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setAlertError(null);

      await axios({
        method: "post",
        url: "/api/map_route_table",
        headers: { "Content-Type": "application/json" },
        data: {
          username: selectedMonitor
            ? selectedMonitor
            : monitorFilters && monitorFilters.length > 0
            ? monitorFilters[0]
            : "",
          startdate: selectedStartDate
            ? selectedStartDate
            : moment().format("YYYY-MM-DD"),
          enddate: selectedEndDate
            ? selectedEndDate
            : moment().format("YYYY-MM-DD"),
        },
      })
        .then((response) => {
          setIsLoading(false);

          const responseData = response.data.table;

          let latitudeTotal = 0;
          let longitudeTotal = 0;
          let dataLength = responseData.length;

          responseData.forEach(({ visitLatitude, visitLongitude }) => {
            latitudeTotal += visitLatitude;
            longitudeTotal += visitLongitude;
          });

          latitudeTotal = latitudeTotal / dataLength;
          longitudeTotal = longitudeTotal / dataLength;

          const averageLatLng = { lat: latitudeTotal, lng: longitudeTotal };

          setMapCenter(averageLatLng);
          setMarkers(responseData);
        })
        .catch((error) => {
          console.log("Map Api: ", error);
          setIsLoading(false);
          setAlertError("Something went wrong. Please try again.");
        });
    };

    fetchData();
  }, [monitorFilters, selectedMonitor, selectedStartDate, selectedEndDate]);

  useEffect(() => {
    if (
      (tempSelectedMonitor || (tempSelectedStartDate && tempSelectedEndDate)) &&
      !startDateError &&
      !endDateError
    ) {
      setIsFilterable(true);
    } else {
      setIsFilterable(false);
    }
  }, [
    tempSelectedMonitor,
    tempSelectedStartDate,
    tempSelectedEndDate,
    startDateError,
    endDateError,
  ]);

  const handleChange = (event) => {
    const {
      target: { name, value },
    } = event;

    if (name === "monitor") {
      setTempSelectedMonitor(value);
    }
  };

  const handleSelectStartDate = (newStartDate) => {
    setTempSelectedStartDate(newStartDate);

    if (newStartDate && !tempSelectedEndDate) {
      setStartDateError(false);
      setEndDateError(true);
    } else if (!newStartDate && tempSelectedEndDate) {
      setStartDateError(true);
      setEndDateError(false);
    } else {
      setStartDateError(false);
      setEndDateError(false);
    }
  };

  const handleSelectEndDate = (newEndDate) => {
    setTempSelectedEndDate(newEndDate);

    if (!tempSelectedStartDate && newEndDate) {
      setStartDateError(true);
      setEndDateError(false);
    } else if (tempSelectedStartDate && !newEndDate) {
      setStartDateError(false);
      setEndDateError(true);
    } else {
      setStartDateError(false);
      setEndDateError(false);
    }
  };

  const handleFilter = () => {
    setSelectedMonitor(tempSelectedMonitor);
    setSelectedStartDate(
      tempSelectedStartDate
        ? moment(tempSelectedStartDate?.$d).format("YYYY-MM-DD")
        : ""
    );
    setSelectedEndDate(
      tempSelectedEndDate
        ? moment(tempSelectedEndDate?.$d).format("YYYY-MM-DD")
        : ""
    );
    setIsFiltered(true);
  };

  const handleReset = () => {
    setTempSelectedMonitor(
      monitorFilters && monitorFilters.length > 0 ? monitorFilters[0] : ""
    );
    setSelectedMonitor(
      monitorFilters && monitorFilters.length > 0 ? monitorFilters[0] : ""
    );
    setTempSelectedStartDate(moment().format("YYYY-MM-DD"));
    setSelectedStartDate(moment().format("YYYY-MM-DD"));
    setTempSelectedEndDate(moment().format("YYYY-MM-DD"));
    setSelectedEndDate(moment().format("YYYY-MM-DD"));
    setIsFilterable(false);
    setIsFiltered(false);
  };

  return (
    <div>
      <h2 className="heading_sm_center">Monitor Route</h2>

      {isLoading ? (
        <div className="col-12 d-flex justify-content-center loader">
          <br />
          <div
            className="spinner-border text-info"
            role="status"
            style={{ zIndex: "2" }}
          ></div>
          <div className="loader-message">Loading...</div>
        </div>
      ) : alertError ? (
        <Alert variant="danger">{alertError}</Alert>
      ) : (
        <div className="container-fluid feature_data position-relative">
          <div className="row filter-container">
            <div className="col-6 col-md-3 m-0 filters">
              <FormControl sx={{ width: 200 }} size="small">
                <InputLabel id="monitor" style={{ fontSize: "14px" }}>
                  Monitor
                </InputLabel>
                <Select
                  labelId="monitor"
                  multiple={false}
                  name="monitor"
                  value={tempSelectedMonitor}
                  onChange={handleChange}
                  input={<OutlinedInput label="Monitor" />}
                  renderValue={() => tempSelectedMonitor}
                  MenuProps={MenuProps}
                >
                  {/* <MenuItem value = ""><em>None</em></MenuItem> */}
                  {monitorFilters.map((monitor) => (
                    <MenuItem key={monitor} value={monitor}>
                      {monitor}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div className="col-6 col-md-3 m-0 filters">
                <DatePicker
                  label="Start Date"
                  inputFormat="YYYY/MM/DD"
                  value={tempSelectedStartDate}
                  maxDate={tempSelectedEndDate}
                  disableFuture={true}
                  onChange={handleSelectStartDate}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={startDateError}
                      helperText={null}
                      size="small"
                    />
                  )}
                />
              </div>
              <div className="col-6 col-md-3 m-0 filters">
                <DatePicker
                  label="End Date"
                  inputFormat="YYYY/MM/DD"
                  value={tempSelectedEndDate}
                  minDate={tempSelectedStartDate}
                  disableFuture={true}
                  onChange={handleSelectEndDate}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={endDateError}
                      helperText={null}
                      size="small"
                    />
                  )}
                />
              </div>
            </LocalizationProvider>
            <div className="col-6 col-md-3 m-0 filters">
              <div className="btn-group btn-contained" role="group">
                <button
                  className="filterButton btn btn-success"
                  color="primary"
                  disabled={!isFilterable}
                  onClick={handleFilter}
                >
                  Filter
                </button>
                <button
                  className="resetButton btn btn-light btn-outline"
                  disabled={!isFiltered}
                  onClick={handleReset}
                >
                  <BiReset />
                </button>
              </div>
            </div>
          </div>

          {markers && markers?.length > 0 ? (
            <>
              <div className="row route-table-container">
                <div className="col-md-12 custom_table">
                  <Table striped bordered hover className="route-table">
                    <thead>
                      <tr>
                        <th colSpan={1}>Sequence</th>
                        <th colSpan={3}>Store Name</th>
                        <th colSpan={2}>Start Time</th>
                        <th colSpan={2}>End Time</th>
                        <th colSpan={2}>Latitude</th>
                        <th colSpan={2}>Longtitude</th>
                        <th colSpan={2}>Time Diff</th>
                      </tr>
                    </thead>
                    <tbody>
                      {markers?.map((marker, index) => (
                        <tr key={index}>
                          <td colSpan={1}>{index}</td>
                          <td colSpan={3}>{marker.storeName}</td>
                          <td colSpan={2}>{marker.visitStartTime}</td>
                          <td colSpan={2}>{marker.visitEndTime}</td>
                          <td colSpan={2}>{marker.visitLatitude}</td>
                          <td colSpan={2}>{marker.visitLongitude}</td>
                          <td colSpan={2}>{marker.timeDiff}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <CustomMap mapCenter={mapCenter} markers={markers} />
                </div>
              </div>
            </>
          ) : (
            <span>No data found.</span>
          )}
        </div>
      )}
    </div>
  );
};

export default MonitorRoute;
