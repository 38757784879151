import React, { useState, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "react-dropdown/style.css";
import "../../styles/PhotoDetails.css";
import "../../styles/general.css";
import ReactPanZoom from "react-image-pan-zoom-rotate";

function PhotoDetail(props) {
  const navigate = useNavigate();

  const currentUser = JSON.parse(localStorage.getItem("user"));
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  let { state } = useLocation();
  const [currentState, setCurrentState] = useState(state);
  const pathname = window.location.pathname;

  /*Image Variables*/
  const [images, setImages] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);

  /*Button Variables*/
  const [nextButton, setNextButton] = useState(true);
  const [previousButton, setPreviousButton] = useState(false);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [flag, setFlag] = useState(false);

  /*Metadata Variables*/
  const [metaData, setMetaData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      setErrorMessage(null);

      if (currentState) {
        setImages(currentState.images);
        setPage(currentState.page);
        setTotalPages(currentState.totalPages);

        if (!flag) setImageIndex(currentState.imageIndex);

        if (currentState.images.length === 1) setNextButton(false);

        if (currentState.imageIndex >= 0 && currentState.page >= 0)
          setPreviousButton(true);
      } else {
        try {
          await fetch("/api/filteredBrandImages", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              country: state.countryToShow,
              city: state.citiesToShow,
              category: state.categoriesToShow,
              channel: state.selectedChannel,
              store: state.storesToShow,
              startDate: state.startDate,
              endDate: state.endDate,
              page: page,
              limit: 100,
            }),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data && data.length > 0) {
                setImages(data.images);
                setTotalPages(Math.round(data.length / 100));
              }
            });
        } catch (error) {
          console.log("Brand Images Api Error: ", error);
          setErrorMessage("Internal Server Error! Please try again later.");
          setIsLoading(false);
        }
      }

      try {
        await fetch("/api/photoMetadata", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            filename:
              images && images.length > 0
                ? images[imageIndex].filestoragePath
                : currentState.images[currentState.imageIndex].filestoragePath,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            setMetaData(data?.metadata[0]);
          });
      } catch (error) {
        console.log("Photo Metadata Api Error: ", error);
        setErrorMessage("Internal Server Error! Please try again later.");
        setIsLoading(false);
      }

      setIsLoading(false);
    }

    fetchData();

    if (pathname?.toLowerCase().includes("brand-view"))
      window.history.replaceState(
        null,
        "",
        `/brand-view/photo-details/${imageIndex}`
      );
    else if (pathname?.toLowerCase().includes("rejected-images"))
      window.history.replaceState(null, "", `/photo-details/${imageIndex}`);

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [currentState, flag, imageIndex, page]);

  const next = () => {
    if (imageIndex >= 0 && imageIndex < images.length) {
      const nextImageIndex = imageIndex + 1;

      setImageIndex(nextImageIndex);
      setPreviousButton(true);
      setFlag(true);

      if (nextImageIndex === images.length - 1 && page <= totalPages) {
        setCurrentState(null);
        setNextButton(false);
      } else if (nextImageIndex === images.length) {
        const nextPage = page + 1;

        setCurrentState(null);
        setPage(nextPage);
        setImageIndex(0);

        if (nextPage === totalPages) {
          setNextButton(false);
        }
      }
    } else {
      setNextButton(false);
      setPreviousButton(true);
    }
  };

  const previous = () => {
    if (page >= 0) {
      const previousImageIndex = imageIndex - 1;

      setImageIndex(previousImageIndex);
      setNextButton(true);
      setFlag(true);

      if (previousImageIndex === 0 && page === 0) {
        setCurrentState(null);
        setPreviousButton(false);
      } else if (previousImageIndex < 0) {
        const previousPage = page - 1;

        setCurrentState(null);
        setPage(previousPage);
        setImageIndex(0);

        if (previousPage === 0) {
          setPreviousButton(false);
        }
      }
    } else {
      setNextButton(true);
      setPreviousButton(false);
    }
  };

  const navigateToPreviousPage = () => {
    window.history.back();
  };
  console.log(metaData, " meta data");
  return (
    <div className="container-fluid main_container">
      <div>
        {/* <LoggedUser logOut = {props.logOut}/> */}
        <div className="row align-items-center justify-content-between mb-1">
          <div className="page_heading col-5 p-0">
            <h4 className="page_heading_primary">
              Photo
              <span className="page_heading_secondary">&nbsp;Details</span>
            </h4>
          </div>
          <div className="col-2">
            <button className="button_primary" onClick={navigateToPreviousPage}>
              Back
            </button>
          </div>
        </div>
        {isLoading ? (
          <div className="d-flex justify-content-center">
            <br />
            <br />
            <div className="spinner-border text-info" role="status"></div>
          </div>
        ) : errorMessage ? (
          <span>{errorMessage}</span>
        ) : (
          images &&
          images.length > 0 && (
            <div className="photoDetailContainer row">
              <div className="col-8 p-0">
                <ReactPanZoom
                  image={
                    "https://storage.googleapis.com/staging-api-uploads/" +
                    images[imageIndex].filestoragePath
                  }
                  alt="Image alt text"
                  style={{ transform: 'rotate(90deg)' }}
                />

                {/* <img src={"https://storage.googleapis.com/staging-api-uploads/" + images[imageIndex].filestoragePath} alt="" width="500px" height="470px" style={{ objectFit: '', margin: 'auto', display: 'block' }} /> */}
              </div>
              <div className="col-4 p-0">
                <div className="metadata">
                  <h4 style={{ fontWeight: "600" }}>Photo's Metadata</h4>
                  <div
                    className="row"
                    style={{ fontSize: "14px", marginTop: "0.5em" }}
                  >
                    <div className="col-6 p-0">
                      <span style={{ fontWeight: "500" }}>Category Name:</span>
                    </div>
                    <div className="col-6 p-0">
                      {metaData ? metaData.categoryName : "NA"}
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{ fontSize: "14px", marginTop: "0.5em" }}
                  >
                    <div className="col-6 p-0">
                      <span style={{ fontWeight: "500" }}>Company Group:</span>
                    </div>
                    <div className="col-6 p-0">
                      {metaData ? metaData.companyGroupName : "NA"}
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{ fontSize: "14px", marginTop: "0.5em" }}
                  >
                    <div className="col-6 p-0">
                      <span style={{ fontWeight: "500" }}>Company:</span>
                    </div>
                    <div className="col-6 p-0">
                      {metaData ? metaData.companyName : "NA"}
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{ fontSize: "14px", marginTop: "0.5em" }}
                  >
                    <div className="col-6 p-0">
                      <span style={{ fontWeight: "500" }}>Store City:</span>
                    </div>
                    <div className="col-6 p-0">
                      {metaData ? metaData.storeCity : "NA"}
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{ fontSize: "14px", marginTop: "0.5em" }}
                  >
                    <div className="col-6 p-0">
                      <span style={{ fontWeight: "500" }}>Store Code:</span>
                    </div>
                    <div className="col-6 p-0">
                      {metaData ? metaData.storeCode : "NA"}
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{ fontSize: "14px", marginTop: "0.5em" }}
                  >
                    <div className="col-6 p-0">
                      <span style={{ fontWeight: "500" }}>Store Name:</span>
                    </div>
                    <div className="col-6 p-0">
                      {metaData ? metaData.storeName : "NA"}
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{ fontSize: "14px", marginTop: "0.5em" }}
                  >
                    <div className="col-6 p-0">
                      <span style={{ fontWeight: "500" }}>
                        Visit Data Upload Date:
                      </span>
                    </div>
                    <div className="col-6 p-0">
                      {metaData
                        ? moment(
                            metaData.visitDataUploadDate,
                            "ddd, DD MMM YYYY HH:mm:ss [GMT]"
                          ).format("ddd, DD MMM YYYY")
                        : "NA"}
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{ fontSize: "14px", marginTop: "0.5em" }}
                  >
                    <div className="col-6 p-0">
                      <span style={{ fontWeight: "500" }}>Visit Date:</span>
                    </div>
                    <div className="col-6 p-0">
                      {metaData
                        ? moment(
                            metaData.visitDate,
                            "ddd, DD MMM YYYY HH:mm:ss [GMT]"
                          ).format("ddd, DD MMM YYYY")
                        : "NA"}
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{ fontSize: "14px", marginTop: "0.5em" }}
                  >
                    <div className="col-6 p-0">
                      <span style={{ fontWeight: "500" }}>Visit ID:</span>
                    </div>
                    <div className="col-6 p-0">
                      {metaData ? metaData.visitID : "NA"}
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{ fontSize: "14px", marginTop: "0.5em" }}
                  >
                    <div className="col-6 p-0">
                      <span style={{ fontWeight: "500" }}>Visit Latitude:</span>
                    </div>
                    <div className="col-6 p-0">
                      {metaData ? metaData.visitLatitude : "NA"}
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{ fontSize: "14px", marginTop: "0.5em" }}
                  >
                    <div className="col-6 p-0">
                      <span style={{ fontWeight: "500" }}>
                        Visit Longitude:
                      </span>
                    </div>
                    <div className="col-6 p-0">
                      {metaData ? metaData.visitLongitude : "NA"}
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{ fontSize: "14px", marginTop: "0.5em" }}
                  >
                    <div className="col-6 p-0">
                      <span style={{ fontWeight: "500" }}>Count Total:</span>
                    </div>
                    <div className="col-6 p-0">
                      {metaData ? metaData?.PhotoCountTotalbyCategory : "NA"}
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{ fontSize: "14px", marginTop: "0.5em" }}
                  >
                    <div className="col-6 p-0">
                      <span style={{ fontWeight: "500" }}>Photo ID:</span>
                    </div>
                    <div className="col-6 p-0">
                      {metaData ? metaData?.PhotoID : "NA"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="scrollButtons">
                <button
                  className="pagination_previous"
                  onClick={previous}
                  disabled={!previousButton}
                >
                  <NavigateBeforeIcon />
                  Previous
                </button>
                <button
                  className="pagination_next"
                  onClick={next}
                  disabled={!nextButton}
                >
                  Next
                  <NavigateNextIcon />
                </button>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
}

export default PhotoDetail;
