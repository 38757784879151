import React, { useEffect, useState } from "react";
import { Alert, Dropdown } from "react-bootstrap";
import DataTable from "../../components/DataTable";
import axios from "axios";
import "react-medium-image-zoom/dist/styles.css";
import "../../styles/featureData.scss";
import LogUserActivity from "../../components/LogUserActivity";

const columnHeaders = [
  "Channel",
  "Customer",
  "Category",
  "Competition",
  // "Promo Comments",
  // "Original Price",
  "Price In Flyer",
  // "Price Difference",
  // "% Discount",
  "SKU Names",
  "Planned Discount",
  "Planned Status",
  // "Currency",
  "Week",
  "Type Of Promotion",
  "Size",
  "Quarter",
  "Slot Unique Number",
  "Start Date",
  "End Date",
  "Year",
];
const TableView = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [alertError, setAlertError] = useState();
  const [filtersApplied, setFiltersApplied] = useState(false);
 const [logId, setLogId] = useState(null);
  /* DATA TABLE VARIABLES */
  const [rows, setRows] = useState([]);

  /* FILTER VARIABLES */
  const [yearFilters, setYearFilters] = useState([]);
  const [weekFilters, setWeekFilters] = useState([]);
  const [categoryFilters, setCategoryFilters] = useState([]);
  const [customerFilters, setCustomerFilters] = useState([]);
  const [channelFilters, setChannelFilters] = useState([]);
  const [barGraphCategorySelectedAll, setBarGraphCategorySelectedAll] =
    useState(false);
  const [barGraphCustomerSelectedAll, setBarGraphCustomerSelectedAll] =
    useState(false);
  const [categorySelectedAll, setCategorySelectedAll] = useState(false);
  const [yearSelectedAll, setYearSelectedAll] = useState(false);
  const [customerSelectedAll, setCustomerSelectedAll] = useState(false);
  const [channelSelectedAll, setChannelSelectedAll] = useState(false);
  const [weekSelectedAll, setWeekSelectedAll] = useState(false);

  /* LINE GRAPH FILTER SELECTED VARIABLES */
  const [categoriesToShow, setCategoriesToShow] = useState([]);
  const [barGraphCategoriesToShow, setBarGraphCategoriesToShow] = useState([]);
  const [barGraphCustomersToShow, setBarGraphCustomersToShow] = useState([]);
  const [customersToShow, setCustomersToShow] = useState([]);
  const [channelsToShow, setChannelsToShow] = useState([]);
  const [yearsToShow, setYearsToShow] = useState([]);
  const [weeksToShow, setWeeksToShow] = useState([]);
  const [competitionsToShow, setCompetitionsToShow] = useState([]);
  const columnDetails = {
    featureDataChannel: { type: "string", width: 110 },
    featureDataCustomer: { type: "string", width: 110 },
    featureDataCategory: { type: "string", width: 110 },
    featureDataCompetition: { type: "string", width: 110 },
    // featureDataPromoComments: { type: "string", width: 120 },
    // featureDataOriginalPrice: { type: "number", width: 110 },
    featureDataDiscountPrice: { type: "number", width: 110 },
    // featureDataPriceDifference: { type: "number", width: 120 },
    // PercentDisc: { type: "number", width: 140 },
    featureDataSKUNames: { type: "string", width: 110 },
    Planned_discount: { type: "string", width: 110 },
    PlannedStatus: { type: "string", width: 110 },
    // featureDataCurrancy: { type: "number", width: 110 },
    featureDataWeek: { type: "string", width: 110 },
    featureDataTypeOfPromotion: { type: "string", width: 120 },
    featureDataSize: { type: "string", width: 110 },
    featureDataQuarter: { type: "string", width: 110 },
    featureDataSlotUniqueNumber: { type: "string", width: 130 },
    featureDataStartDate: { type: "date", width: 110 },
    featureDataEndDate: { type: "date", width: 110 },
    featureDataYear: { type: "string", width: 110 },
  };

  useEffect(() => {
    setAlertError(null);

    const getFilterValues = async () => {
      setIsLoading(true);

      await axios({
        method: "post",
        url: "/api/filtervalues",
        headers: { "Content-Type": "application/json" },
        data: { category: categoriesToShow },
        timeout: 180000,
      })
        .then((response) => {
          setIsLoading(false);

          const responseData = response.data;

          setCategoryFilters(responseData.Category);
          setCustomerFilters(responseData.Customer);
          setWeekFilters(responseData.Week.map(String));
          setYearFilters(responseData.fiscalYear);
          setChannelFilters(responseData.channel);
        })
        .catch((error) => {
          console.log("Get Filters Api: ", error);
          setIsLoading(false);
          setAlertError("Something went wrong. Please try again.");
        });
    };
    LogUserActivity("featureData Table_view Activity Started", props?.currentUser?.user, setLogId, setAlertError);
    getFilterValues();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    setAlertError(null);

    const fetchData = async () => {
      await axios({
        method: "post",
        url: "/api/FeatureTableData",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify({
          category: barGraphCategoriesToShow,
          customer: barGraphCustomersToShow,
          week: weeksToShow,
          channel: channelsToShow,
          fiscalYear: yearsToShow,
        }),
      })
        .then((response) => {
          setIsLoading(false);

          const responseData = response.data;
          const tableData = responseData?.table?.map((data) => {
            return {
              ...data,
              PercentDisc: Math.round(data.PercentDisc * 10) / 10,
            };
          });

          setRows(tableData);
        })
        .catch((error) => {
          console.log("Feature Table Api: ", error);
          setIsLoading(false);
          setAlertError("Something went wrong. Please try again.");
        });
    };
    if (
      barGraphCategoriesToShow &&
      barGraphCustomersToShow &&
      weeksToShow &&
      channelsToShow &&
      yearsToShow &&
      !filtersApplied
    ) {
      fetchData();
    }
  }, []);

  console.log(
    barGraphCategoriesToShow,
    barGraphCustomersToShow,
    weeksToShow,
    channelsToShow,
    yearsToShow
  );
  const applyfilters = () => {
    setFiltersApplied(true);
    setIsLoading(true);
    setAlertError(null);

    const getFilteredValues = async () => {
      await axios({
        method: "post",
        url: "/api/FeatureTableData",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify({
          category: barGraphCategoriesToShow,
          customer: barGraphCustomersToShow,
          week: weeksToShow,
          channel: channelsToShow,
          fiscalYear: yearsToShow,
        }),
      })
        .then((response) => {
          setIsLoading(false);

          const responseData = response.data;
          const tableData = responseData?.table?.map((data) => {
            return {
              ...data,
              PercentDisc: Math.round(data.PercentDisc * 10) / 10,
            };
          });

          setRows(tableData);
        })
        .catch((error) => {
          console.log("Feature Table Api: ", error);
          setIsLoading(false);
          setAlertError("Something went wrong. Please try again.");
        });
    };

    getFilteredValues();
  };

  const checkBoxFilterHandle = (e, flag) => {
    const { checked, value } = e.target;

    if (flag === "category") {
      if (checked) {
        const checkedValue = categoriesToShow.includes(value);

        if (!checkedValue) {
          const tempCategoryFilters = [...categoriesToShow, value];
          setCategoriesToShow(tempCategoryFilters);

          if (tempCategoryFilters.length === categoryFilters.length) {
            setCategorySelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = categoriesToShow.filter((element) => {
          return element !== value;
        });

        setCategoriesToShow(uncheckedValue);
        setCategorySelectedAll(false);
      }
    }

    if (flag === "barGraphCategories") {
      if (checked) {
        const checkedValue = barGraphCategoriesToShow.includes(value);

        if (!checkedValue) {
          const tempCategoryFilters = [...barGraphCategoriesToShow, value];
          setBarGraphCategoriesToShow(tempCategoryFilters);

          if (tempCategoryFilters.length === categoryFilters.length) {
            setCategorySelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = barGraphCategoriesToShow.filter((element) => {
          return element !== value;
        });

        setBarGraphCategoriesToShow(uncheckedValue);
        setBarGraphCategorySelectedAll(false);
      }
    }
    if (flag === "barGraphCustomer") {
      if (checked) {
        const checkedValue = barGraphCustomersToShow.includes(value);

        if (!checkedValue) {
          const tempCustomerFilters = [...barGraphCustomersToShow, value];
          setBarGraphCustomersToShow(tempCustomerFilters);

          if (tempCustomerFilters.length === customerFilters.length) {
            setCustomerSelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = barGraphCustomersToShow.filter((element) => {
          return element !== value;
        });

        setBarGraphCustomersToShow(uncheckedValue);
        setBarGraphCustomerSelectedAll(false);
      }
    }
    if (flag === "year") {
      if (checked) {
        const checkedValue = yearsToShow.includes(value);

        if (!checkedValue) {
          const tempyearFilters = [...yearsToShow, value];
          setYearsToShow(tempyearFilters);

          if (tempyearFilters.length === yearFilters.length) {
            setYearSelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = yearsToShow.filter((element) => {
          return element !== value;
        });

        setYearsToShow(uncheckedValue);
        setYearSelectedAll(false);
      }
    }
    if (flag === "week") {
      // const checkedValue = weeksToShow.includes(value);
      // console.log(checkedValue)
      if (checked) {
        const checkedValue = weeksToShow.includes(value);
        console.log(value);
        if (!checkedValue) {
          const tempweekFilters = [...weeksToShow, value];
          setWeeksToShow(tempweekFilters);

          if (tempweekFilters.length === weekFilters.length) {
            setWeekSelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = weeksToShow.filter((element) => {
          return element !== value;
        });

        setWeeksToShow(uncheckedValue);
        setWeekSelectedAll(false);
      }
    }
    if (flag === "customer") {
      // const checkedValue = weeksToShow.includes(value);
      // console.log(checkedValue)
      if (checked) {
        const checkedValue = customersToShow.includes(value);
        console.log(value);
        if (!checkedValue) {
          const tempweekFilters = [...customersToShow, value];
          setCustomersToShow(tempweekFilters);

          if (tempweekFilters.length === weekFilters.length) {
            setCustomerSelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = customersToShow.filter((element) => {
          return element !== value;
        });

        setCustomersToShow(uncheckedValue);
        setCustomerSelectedAll(false);
      }
    }

    if (flag === "channel") {
      // const checkedValue = weeksToShow.includes(value);
      // console.log(checkedValue)
      if (checked) {
        const checkedValue = channelsToShow.includes(value);
        console.log(value);
        if (!checkedValue) {
          const tempweekFilters = [...channelsToShow, value];
          setChannelsToShow(tempweekFilters);

          if (tempweekFilters.length === weekFilters.length) {
            setChannelSelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = channelsToShow.filter((element) => {
          return element !== value;
        });

        setChannelsToShow(uncheckedValue);
        setChannelSelectedAll(false);
      }
    }
  };

  const selectAll = (e, flag) => {
    if (flag === "Categories") {
      if (!e.target.checked) {
        setCategoriesToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          categoryFilters.map((category) => {
            return category;
          })
        );

        setCategoriesToShow(filterArray[0]);
      }

      setCategorySelectedAll(e.target.checked);
    }
    if (flag === "barGraphCategories") {
      if (!e.target.checked) {
        setBarGraphCategoriesToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          categoryFilters.map((category) => {
            return category;
          })
        );

        setBarGraphCategoriesToShow(filterArray[0]);
      }

      setBarGraphCategorySelectedAll(e.target.checked);
    }
    if (flag === "barGraphCustomers") {
      if (!e.target.checked) {
        setBarGraphCustomersToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          customerFilters.map((customer) => {
            return customer;
          })
        );
        setBarGraphCustomersToShow(filterArray[0]);
      }

      setBarGraphCustomerSelectedAll(e.target.checked);
    }
    if (flag === "years") {
      if (!e.target.checked) {
        setYearsToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          yearFilters.map((year) => {
            return year;
          })
        );

        setYearsToShow(filterArray[0]);
      }

      setYearSelectedAll(e.target.checked);
    }
    if (flag === "weeks") {
      if (!e.target.checked) {
        setWeeksToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          weekFilters.map((week) => {
            return week;
          })
        );

        setWeeksToShow(filterArray[0]);
      }

      setWeekSelectedAll(e.target.checked);
    }
    if (flag === "Customers") {
      if (!e.target.checked) {
        setCustomersToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          customerFilters.map((customer) => {
            return customer;
          })
        );

        setCustomersToShow(filterArray[0]);
      }

      setCustomerSelectedAll(e.target.checked);
    }
    if (flag === "channels") {
      if (!e.target.checked) {
        setChannelsToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          channelFilters.map((channel) => {
            return channel;
          })
        );

        setChannelsToShow(filterArray[0]);
      }

      setChannelSelectedAll(e.target.checked);
    }
    // if (flag === "Competitions") {
    //     if (!e.target.checked) {
    //         setCompetitionsToShow([]);
    //     }
    //     else {
    //         let filterArray = [];

    //         filterArray.push(competitionFilters.map((competition) => {
    //             return competition;
    //         }));

    //         setCompetitionsToShow(filterArray[0]);
    //     }

    //     setCompetitionSelectedAll(e.target.checked);
    // }
  };

  return (
    <div className="container-fluid feature_data position-relative">
      <h2 className="heading_sm_center">Table View</h2>

      {isLoading && rows.length < 1 ? (
        <div className="col-12 d-flex justify-content-center loader">
          <br />
          <div
            className="spinner-border text-info"
            role="status"
            style={{ zIndex: "2" }}
          ></div>
          <div className="loader-message">Loading...</div>
        </div>
      ) : alertError ? (
        <Alert variant="danger">{alertError}</Alert>
      ) : (
        <div className="row content_main_row">
          <div className="filters col-md-12">
            <div className="row w-100 filters_row justify-content-between">
              <div className="col py-0">
                <div
                  className="filters_right w-100 justify-content-center"
                  style={{ gap: "5px" }}
                >
                  <div className="filter-dropdowns">
                    <Dropdown
                      className={`${
                        !(yearsToShow.length > 0)
                          ? "dropDown_disabled"
                          : "dropdown_selected"
                      }`}
                    >
                      <Dropdown.Toggle variant="secondary">
                        Year
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div
                          className="p-3 d-flex flex-column"
                          style={{ gap: "5px" }}
                        >
                          <div className="d-flex" style={{ gap: "0 8px" }}>
                            <input
                              type="checkbox"
                              name="selectAll"
                              checked={yearSelectedAll}
                              onChange={(e) => selectAll(e, "years")}
                            />
                            <label htmlFor="selectAll">Select All</label>
                          </div>

                          {yearFilters?.map((year, index) => (
                            <div
                              key={index}
                              className="d-flex"
                              style={{ gap: "0 10px" }}
                            >
                              <input
                                type="checkbox"
                                value={year}
                                onChange={(e) =>
                                  checkBoxFilterHandle(e, "year")
                                }
                                checked={
                                  yearsToShow.includes(year) ? true : false
                                }
                              />
                              <label style={{ fontSize: "12px" }}>{year}</label>
                            </div>
                          ))}
                        </div>
                      </Dropdown.Menu>
                      {/* <Dropdown.Menu>
                                                {yearFilters?.map((filter, index) =>
                                                    <Dropdown.Item key={index} onClick={(event) => setYearSelectedFilter(event.target.text)}>{filter}</Dropdown.Item>
                                                )}
                                            // </Dropdown.Menu> */}
                    </Dropdown>
                  </div>

                  <div className="filter-dropdowns">
                    <Dropdown
                      className={`${
                        !(weeksToShow.length > 0)
                          ? "dropDown_disabled"
                          : "dropdown_selected"
                      }`}
                    >
                      <Dropdown.Toggle variant="secondary">
                        Week
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div
                          className="p-3 d-flex flex-column"
                          style={{ gap: "5px" }}
                        >
                          <div className="d-flex" style={{ gap: "0 8px" }}>
                            <input
                              type="checkbox"
                              name="selectAll"
                              checked={weekSelectedAll}
                              onChange={(e) => selectAll(e, "weeks")}
                            />
                            <label htmlFor="selectAll">Select All</label>
                          </div>

                          {weekFilters?.map((week, index) => (
                            <div
                              key={index}
                              className="d-flex"
                              style={{ gap: "0 10px" }}
                            >
                              <input
                                type="checkbox"
                                value={week}
                                onChange={(e) =>
                                  checkBoxFilterHandle(e, "week")
                                }
                                checked={
                                  weeksToShow.includes(week) ? true : false
                                }
                              />
                              <label style={{ fontSize: "12px" }}>{week}</label>
                            </div>
                          ))}
                        </div>
                      </Dropdown.Menu>
                      {/* <Dropdown.Menu>
                                                {weekFilters?.map((filter, index) =>
                                                    <Dropdown.Item key={index} onClick={(event) => setWeekSelectedFilter(event.target.text)}>{filter}</Dropdown.Item>
                                                )}
                                            </Dropdown.Menu> */}
                    </Dropdown>
                  </div>
                </div>
              </div>

              <div className="col-md-8 col-xl-7">
                <div
                  className="filters_right w-100 justify-content-center"
                  style={{ gap: "5px" }}
                >
                  <div className="">
                    <Dropdown
                      className={`${
                        !(barGraphCategoriesToShow.length > 0)
                          ? "dropDown_disabled"
                          : "dropdown_selected"
                      }`}
                    >
                      <Dropdown.Toggle variant="secondary">
                        Category
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <div
                          className="p-3 d-flex flex-column"
                          style={{ gap: "5px" }}
                        >
                          <div className="d-flex" style={{ gap: "0 8px" }}>
                            <input
                              type="checkbox"
                              name="selectAll"
                              checked={barGraphCategorySelectedAll}
                              onChange={(e) =>
                                selectAll(e, "barGraphCategories")
                              }
                            />
                            <label htmlFor="selectAll">Select All</label>
                          </div>

                          {categoryFilters?.map((category, index) => (
                            <div
                              key={index}
                              className="d-flex"
                              style={{ gap: "0 10px" }}
                            >
                              <input
                                type="checkbox"
                                value={category}
                                onChange={(e) =>
                                  checkBoxFilterHandle(e, "barGraphCategories")
                                }
                                checked={
                                  barGraphCategoriesToShow.includes(category)
                                    ? true
                                    : false
                                }
                              />
                              <label style={{ fontSize: "12px" }}>
                                {category}
                              </label>
                            </div>
                          ))}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <div className="">
                    <Dropdown
                      className={`${
                        !(barGraphCustomersToShow.length > 0)
                          ? "dropDown_disabled"
                          : "dropdown_selected"
                      }`}
                    >
                      <Dropdown.Toggle variant="secondary">
                        Customer
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div
                          className="p-3 d-flex flex-column"
                          style={{ gap: "5px" }}
                        >
                          <div className="d-flex" style={{ gap: "0 8px" }}>
                            <input
                              type="checkbox"
                              name="selectAll"
                              checked={barGraphCustomerSelectedAll}
                              onChange={(e) => selectAll(e, "barGraphCustomer")}
                            />
                            <label htmlFor="selectAll">Select All</label>
                          </div>

                          {customerFilters?.map((customer, index) => (
                            <div
                              key={index}
                              className="d-flex"
                              style={{ gap: "0 10px" }}
                            >
                              <input
                                type="checkbox"
                                value={customer}
                                onChange={(e) =>
                                  checkBoxFilterHandle(e, "barGraphCustomer")
                                }
                                checked={
                                  barGraphCustomersToShow.includes(customer)
                                    ? true
                                    : false
                                }
                              />
                              <label style={{ fontSize: "12px" }}>
                                {customer}
                              </label>
                            </div>
                          ))}
                        </div>
                      </Dropdown.Menu>
                      {/* <Dropdown.Menu>
                                                {customerFilters?.map((filter, index) =>
                                                    <Dropdown.Item key={index} onClick={(event) => setCustomerSelectedFilter(event.target.text)}>{filter}</Dropdown.Item>
                                                )}
                                            </Dropdown.Menu> */}
                    </Dropdown>
                  </div>

                  <div className="">
                    <Dropdown
                      className={`${
                        !(channelsToShow.length > 0)
                          ? "dropDown_disabled"
                          : "dropdown_selected"
                      }`}
                    >
                      <Dropdown.Toggle variant="secondary">
                        Channel
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <div
                          className="p-3 d-flex flex-column"
                          style={{ gap: "5px" }}
                        >
                          <div className="d-flex" style={{ gap: "0 8px" }}>
                            <input
                              type="checkbox"
                              name="selectAll"
                              checked={channelSelectedAll}
                              onChange={(e) => selectAll(e, "channels")}
                            />
                            <label htmlFor="selectAll">Select All</label>
                          </div>

                          {channelFilters?.map((channel, index) => (
                            <div
                              key={index}
                              className="d-flex"
                              style={{ gap: "0 10px" }}
                            >
                              <input
                                type="checkbox"
                                value={channel}
                                onChange={(e) =>
                                  checkBoxFilterHandle(e, "channel")
                                }
                                checked={
                                  channelsToShow.includes(channel)
                                    ? true
                                    : false
                                }
                              />
                              <label style={{ fontSize: "12px" }}>
                                {channel}
                              </label>
                            </div>
                          ))}
                        </div>
                      </Dropdown.Menu>
                      {/* <Dropdown.Menu>
                                                {channelFilters?.map((filter, index) =>
                                                    <Dropdown.Item key={index} onClick={(event) => setChannelSelectedFilter(event.target.text)}>{filter}</Dropdown.Item>
                                                )}
                                            </Dropdown.Menu> */}
                    </Dropdown>
                  </div>
                    <button className="btn btn-success" onClick={applyfilters}>
                    Filter
                    </button>
                </div>
              </div>
            </div>
          </div>
          {rows && rows.length > 0 && (
            <DataTable
              columnDetails={columnDetails}
              columnHeaders={columnHeaders}
              rows={rows}
              rowID="featureDataID"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default TableView;
