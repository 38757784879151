import React, { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';

const TreeMap = ({chartData}) => {
  // const chartData = [
  //   ['Category 1', 'By Display Type', 20],
  //   ['Category 2', 'By Display Type', 30],
  //   ['Category 3', 'By Display Type', 10],
  //   ['Category 4', 'By Display Type', 40],
  //   ['Category 5', 'By Display Type', 3],
  // ];

  const [data, setData] = useState([
    ['Location', 'Parent', 'Value'],
    ['By Display Type', null, 0],
  ]);

  useEffect(() => {
    const uniqueData = new Set(data.map(JSON.stringify)); // Convert existing data to a Set for uniqueness
    const updatedData = [...uniqueData].map(JSON.parse); // Convert back to array format

    chartData?.forEach(item => {
      const itemString = JSON.stringify(item);
      if (!uniqueData.has(itemString)) {
        updatedData.push(item); // Push only if it doesn't exist in the uniqueData set
        uniqueData.add(itemString);
      }
    });

    setData(updatedData);
  }, [chartData]);

  const options = {
    height: 400,
    minColor: '#0A5A87', // Customize the color of the smallest values
    midColor: '#5B91AF', // Customize the color of the medium values
    maxColor: '#91B5C9', // Customize the color of the largest values
    textStyle: {
      fontName: 'Arial', // Customize the font family
      fontSize: 12, // Customize the font size
      color: '#ffffff', // Customize the font color
    },
    // tooltip: {
    //     textStyle: {
    //       fontName: 'Arial', // Customize the tooltip font family
    //       fontSize: 12, // Customize the tooltip font size
    //       color: '#ffffff', // Customize the tooltip font color
    //     },
    //     isHtml: true, // Enable HTML content in the tooltip
    //   },
  };

  return (
    <>
      <Chart
        chartType="TreeMap"
        width="100%"
        height="400px"
        data={data}
        options={options}
      />
    </>
  );
};

export default TreeMap;
