import axios from "axios";
import React, { useState, useEffect } from "react";
import { Alert, Button, Dropdown, Modal, Table } from "react-bootstrap";
import CustomDataMap from "../../components/CustomDataMap";
import DoubleBarChart from "../../components/DoubleBarChart";
import DynamicDataTable from "../../components/DynamicDataTable";
import "../../styles/monitorAnalytics.scss";
import DataTable from "../../components/DataTable";
import { BsBookshelf } from "react-icons/bs";
import LogUserActivity from "../../components/LogUserActivity";

const OnSehlfAvailability = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [alertError, setAlertError] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertModal, setAlertModal] = useState(null);
  const [logId, setLogId] = useState(null);

  const [csvData, setCSVData] = useState();
  const [buttonClicked, setButtonClicked] = useState(false);
  // FILTER DROPDOWN  OPTIONS
  const [regionFilters, setRegionFilters] = useState([]);
  const [monthFilters, setMonthFilters] = useState([]);
  const [yearFilters, setYearFilters] = useState([]);
  const [categoryFilters, setCategoryFilters] = useState([]);
  const [customerFilters, setCustomerFilters] = useState([]);
  const [cityFilters, setCityFilters] = useState([]);

  // TABLE DATA
  // LOWEST ONSHELF WITH CATEGORY
  const [tableLoading, setTableLoading] = useState(false);
  const [columnDetails, setColumnDetails] = useState();
  const [rows, setRows] = useState([]);
  const [columnHeaders, setColumnHeaders] = useState([]);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(50);
  const [rowCount, setRowCount] = React.useState();
  //  WAVE DATATABLE
  const [waveTableLoading, setWaveTableLoading] = useState(false);
  const [waveColumnDetails, setWaveColumnDetails] = useState();
  const [waveRows, setWaveRows] = useState([]);
  const [waveColumnHeaders, setWaveColumnHeaders] = useState([]);
  const [wavePage, setWavePage] = React.useState(0);
  const [wavePageSize, setWavePageSize] = React.useState(50);
  const [waveRowCount, setWaveRowCount] = React.useState();

  // LOWEST ONSHELF WITHOUT CATEGORY
  const [lwcTableLoading, setLwcTableLoading] = useState(false);
  const [lwcColumnDetails, setLwcColumnDetails] = useState();
  const [lwcRows, setLwcRows] = useState([]);
  const [lwcColumnHeaders, setLwcColumnHeaders] = useState([]);
  const [lwcPage, setLwcPage] = React.useState(0);
  const [lwcPageSize, setLwcPageSize] = React.useState(50);
  const [lwcRowCount, setLwcRowCount] = React.useState();

  // SELECTED FILTER VALUE
  const [availabilityPercentage, setAvailabilityPercentage] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [categorySelectedAll, setCategorySelectedAll] = useState(false);
  const [customerSelectedAll, setCustomerSelectedAll] = useState(false);
  const [citySelectedAll, setCitySelectedAll] = useState(false);
  const [regionSelectedAll, setRegionSelectedAll] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(false);

  /* CHART DATASET VARIABLES */
  const [categoryDataset, setCategoryDataset] = useState("");
  const [customerDataset, setCustomerDataset] = useState("");
  const [monthDataset, setMonthDataset] = useState("");
  const [cityDataset, setCityDataset] = useState("");
  const [tableData, setTableData] = useState(null);

  // REGIONS DATA
  const [regionData, setRegionData] = useState([]);
  const [customersToShow, setCustomersToShow] = useState([]);
  const [citiesToShow, setCitiesToShow] = useState([]);
  const [regionsToShow, setRegionsToShow] = useState([]);
  const [categoriesToShow, setCategoriesToShow] = useState([]);

  const timeOut = () => {
    const timeId = setTimeout(() => {
      setAlertSuccess(null);
      setAlertError(null);
    }, 2000);

    return () => {
      clearTimeout(timeId);
    };
  };

  useEffect(() => {
    const currentMonth = new Date().getMonth(); // Adding 1 because getMonth() returns a zero-based index
    const currentYear = new Date().getFullYear();
    let fiscalYearStartYear;
  
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
  
    fiscalYearStartYear = currentMonth >= 6 ? currentYear : currentYear - 1;
  
    const fiscalYearLabel = `FY${fiscalYearStartYear.toString().slice(-2)}${(
      fiscalYearStartYear + 1
    )
      .toString()
      .slice(-2)}`;
  
    setSelectedYear(fiscalYearLabel);
    setSelectedMonth(monthNames[currentMonth]);
    setIsFirstTime(true);
  }, []);
  
  useEffect(() => {
    const tempColumnDetails = {};
    const tempWaveColumnDetails = {};
    const tempLwcColumnDetails = {};
    if (columnHeaders) {
      columnHeaders
        .filter(function (column) {
          if (column === "ID") {
            return false; // skip
          }
          return true;
        })
        .map((header) => {
          if (header === "result") {
            tempColumnDetails[header] = { type: "number", flex: 1 };
          } else {
            if (header === "storeName") {
              tempColumnDetails[header] = { type: "string", flex: 1 };
            } else {
              tempColumnDetails[header] = { type: "string", flex: 0.5 };
            }
          }
        });
      setColumnDetails(tempColumnDetails);
    }

    if (waveColumnHeaders) {
      waveColumnHeaders.map((header) => {
        if (header === "storeName") {
          tempWaveColumnDetails[header] = { type: "string", flex: 1 };
        } else {
          tempWaveColumnDetails[header] = { type: "string", flex: 0.3 };
        }
      });
      setWaveColumnDetails(tempWaveColumnDetails);
    }

    if (lwcColumnHeaders) {
      lwcColumnHeaders
        .filter(function (column) {
          if (column === "ID") {
            return false; // skip
          }
          return true;
        })
        .map((header) => {
          if (header === "result") {
            tempLwcColumnDetails[header] = { type: "number", flex: 1 };
          } else {
            if (header === "storeName") {
              tempLwcColumnDetails[header] = { type: "string", flex: 1 };
            } else {
              tempLwcColumnDetails[header] = { type: "string", flex: 0.5 };
            }
          }
        });
      setLwcColumnDetails(tempLwcColumnDetails);
    }
  }, [columnHeaders, waveColumnHeaders, lwcColumnHeaders]);

  console.log(rows, columnDetails, "fsdffsd");

  // LOWEST ONSHELF WITH CATEGORY
  useEffect(() => {
    if (isFirstTime) {
      fetchOsaLowestTableData();
    } else if (selectedMonth && selectedYear && !isFirstTime) {
      fetchOsaLowestTableData();
    }
  }, [page, pageSize, isFirstTime]);
  const fetchOsaLowestTableData = async () => {
    setTableLoading(true);
    await axios({
      method: "post",
      url: "/api/OSA_lowest_percent_table",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        category: categoriesToShow,
        month: selectedMonth,
        year: selectedYear,
        city: citiesToShow,
        customer: customersToShow,
        region: regionsToShow,
        limit: pageSize,
        skip: page,
      }),
    })
      .then((response) => {
        console.log(response, " table response");
        setTableLoading(false);
        const responseData = response?.data;
        setRows(responseData?.table);
        setRowCount(responseData?.rowCount);
        setColumnHeaders(responseData?.columns);
      })
      .catch((error) => {
        setTableLoading(false);
        setAlertError("something went wrong");
        console.log("Get Filters Api: ", error);
      });
  };
  // LOWEST ONSHELF WITHOUT CATEGORY
  useEffect(() => {
    if (isFirstTime) {
      fetchOsaTableData();
    } else if (selectedMonth && selectedYear && !isFirstTime) {
      fetchOsaTableData();
    }
  }, [lwcPage, lwcPageSize, isFirstTime]);
  const fetchOsaTableData = async () => {
    setLwcTableLoading(true);
    await axios({
      method: "post",
      url: "/api/OSA_table",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        month: selectedMonth,
        year: selectedYear,
        city: citiesToShow,
        customer: customersToShow,
        region: regionsToShow,
        limit: lwcPageSize,
        skip: lwcPage,
      }),
    })
      .then((response) => {
        console.log(response, " lowesr without category");
        setLwcTableLoading(false);
        const responseData = response?.data;
        setLwcRows(responseData?.table);
        setLwcRowCount(responseData?.rowCount);
        setLwcColumnHeaders(responseData?.columns);
      })
      .catch((error) => {
        setLwcTableLoading(false);
        setAlertError("something went wrong");
        console.log("Get Filters Api: ", error);
      });
  };
  useEffect(() => {
    if (isFirstTime) {
      fetchOsaWaveData();
    } else if (selectedMonth && selectedYear && !isFirstTime) {
      fetchOsaWaveData();
    }
  }, [wavePageSize, wavePage, isFirstTime]);
  const fetchOsaWaveData = async () => {
    setWaveTableLoading(true);
    await axios({
      method: "post",
      url: "/api/OSA_wave",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        month: selectedMonth,
        year: selectedYear,
        limit: wavePageSize,
        skip: wavePage,
      }),
    })
      .then((response) => {
        console.log(response, "osa wave");
        setWaveTableLoading(false);
        const responseData = response?.data;
        setWaveRows(responseData?.table);
        setWaveRowCount(responseData?.rowCount);
        setWaveColumnHeaders(responseData?.columns);
      })
      .catch((error) => {
        setWaveTableLoading(false);
        setAlertError("something went wrong");
        console.log("Get Filters Api: ", error);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    const getFilterValues = async () => {
      await axios({
        method: "post",
        url: "/api/filter_values_on_shelf",
      })
        .then((response) => {
          if (response?.request?.status === 200) {
            setRegionFilters(response?.data?.region);
            setMonthFilters(response?.data?.month);
            setCategoryFilters(response?.data?.category);
            setCustomerFilters(response?.data?.customer);
            setCityFilters(response?.data?.city);
            setYearFilters(response?.data?.year);
            console.log(response, "filter values");
          }
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("Something went wrong. Please try again.");
        });
    };
    LogUserActivity(
      "OnSehlf Availability Activity Started",
      props?.currentUser?.user,
      setLogId,
      setAlertError
    );
    getFilterValues();
  }, []);

  useEffect(() => {
    setAlertError("");
    setCustomerDataset(null);
    setCategoryDataset(null);
    setCityDataset(null);
    const getCustomerBarChartData = async () => {
      await axios({
        method: "post",
        url: "/api/on_shelf_availbility_customer",
        data: JSON.stringify({
          category: [],
          month: selectedMonth,
          city: [],
          customer: [],
          region: [],
          year: selectedYear,
        }),
      })
        .then((response) => {
          setIsLoading(false);
          setCustomerDataset(response?.data?.data);
          console.log(response, " new reposne");
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("Something went wrong. Please try again.");
        });
    };

    const getCategoryBarChartData = async () => {
      await axios({
        method: "post",
        url: "/api/on_shelf_availbility_category",
        data: JSON.stringify({
          category: [],
          month: selectedMonth,
          city: [],
          customer: [],
          region: [],
          year: selectedYear,
        }),
      })
        .then((response) => {
          setIsLoading(false);
          setCategoryDataset(response?.data?.data);
          console.log(response, " new reposne");
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("Something went wrong. Please try again.");
        });
    };

    const getCityBarChartData = async () => {
      await axios({
        method: "post",
        url: "/api/on_shelf_availbility_city",
        data: JSON.stringify({
          category: [],
          month: selectedMonth,
          city: [],
          customer: [],
          region: [],
          year: selectedYear,
        }),
      })
        .then((response) => {
          setIsLoading(false);
          setCityDataset(response?.data?.data);
          console.log(response, " new reposne");
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("Something went wrong. Please try again.");
        });
    };

    const getTableData = async () => {
      await axios({
        method: "post",
        url: "/api/on_shelf_availbility_customer_category",
        data: JSON.stringify({
          category: [],
          month: selectedMonth,
          city: [],
          customer: [],
          region: [],
          year: selectedYear,
        }),
      })
        .then((response) => {
          setTableData(response?.data);
          console.log(response, " new table");
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("Something went wrong. Please try again.");
        });
    };
    const getAvailabilityTotal = async () => {
      await axios({
        method: "post",
        url: "/api/on_shelf_availbility_total",
        data: JSON.stringify({
          category: [],
          city: [],
          customer: [],
          region: [],
          year: selectedYear,
        }),
      })
        .then((response) => {
          setIsLoading(false);
          console.log(response, "resssss");
          setMonthDataset(response?.data?.data);
          // setIsLoading(false);
          // console.log(response, 'month  total')
          // if (response?.data?.data[0]?.result) {

          //     setAvailabilityPercentage(response?.data?.data[0])
          // } else {
          //     setAvailabilityPercentage('No Data found')
          // }
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAvailabilityPercentage(" ");
          setAlertError("Something went wrong. Please try again.");
        });
    };
    const getAvailabilityPercentage = async () => {
      await axios({
        method: "post",
        url: "/api/on_shelf_availbility_percent_total",
        data: JSON.stringify({
          category: [],
          month: selectedMonth,
          city: [],
          customer: [],
          region: [],
          year: selectedYear,
        }),
      })
        .then((response) => {
          setIsLoading(false);
          console.log(response, "month  total");
          if (response?.data?.data[0]?.result) {
            setAvailabilityPercentage(response?.data?.data[0]);
          } else {
            setAvailabilityPercentage("No Data found");
          }
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAvailabilityPercentage(" ");
          setAlertError("Something went wrong. Please try again.");
        });
    };
    const getMapData = async () => {
      await axios({
        method: "post",
        url: "/api/region_on_shelf_availbility_city",
        data: JSON.stringify({
          category: [],
          month: selectedMonth,
          city: [],
          customer: [],
          region: [],
          year: selectedYear,
        }),
      })
        .then((response) => {
          if (response?.request?.status === 200) {
            setRegionData(response?.data?.data);
            console.log(response, "regions");
          }
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("Something went wrong. Please try again.");
        });
    };
    if (selectedYear && selectedMonth && isFirstTime) {
      setIsFirstTime(false);
      getAvailabilityTotal();
      getCityBarChartData();
      getCategoryBarChartData();
      getAvailabilityPercentage();
      getCustomerBarChartData();
      getTableData();
      getMapData();
    }
  }, [selectedYear, selectedMonth]);

  const selectAll = (e, flag) => {
    if (flag === "category") {
      if (!e.target.checked) {
        setCategoriesToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          categoryFilters.map((category) => {
            return category;
          })
        );

        setCategoriesToShow(filterArray[0]);
      }

      setCategorySelectedAll(e.target.checked);
    }

    if (flag === "city") {
      if (!e.target.checked) {
        setCitiesToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          cityFilters.map((city) => {
            return city;
          })
        );

        setCitiesToShow(filterArray[0]);
      }

      setCitySelectedAll(e.target.checked);
    }
    if (flag === "region") {
      if (!e.target.checked) {
        setRegionsToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          regionFilters.map((region) => {
            return region;
          })
        );

        setRegionsToShow(filterArray[0]);
      }

      setRegionSelectedAll(e.target.checked);
    }
    if (flag === "customer") {
      if (!e.target.checked) {
        setCustomersToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          customerFilters.map((customer) => {
            return customer;
          })
        );

        setCustomersToShow(filterArray[0]);
      }

      setCustomerSelectedAll(e.target.checked);
    }

    // if (flag === "Competitions") {
    //     if (!e.target.checked) {
    //         setCompetitionsToShow([]);
    //     }
    //     else {
    //         let filterArray = [];

    //         filterArray.push(competitionFilters.map((competition) => {
    //             return competition;
    //         }));

    //         setCompetitionsToShow(filterArray[0]);
    //     }

    //     setCompetitionSelectedAll(e.target.checked);
    // }
  };
  const checkBoxFilterHandle = (e, flag) => {
    const { checked, value } = e.target;

    if (flag === "category") {
      if (checked) {
        const checkedValue = categoriesToShow.includes(value);

        if (!checkedValue) {
          const tempCategoryFilters = [...categoriesToShow, value];
          setCategoriesToShow(tempCategoryFilters);

          if (tempCategoryFilters.length === categoryFilters.length) {
            setCategorySelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = categoriesToShow.filter((element) => {
          return element !== value;
        });

        setCategoriesToShow(uncheckedValue);
        setCategorySelectedAll(false);
      }
    }
    if (flag === "customer") {
      if (checked) {
        const checkedValue = customersToShow.includes(value);

        if (!checkedValue) {
          const tempCustomerFilters = [...customersToShow, value];
          setCustomersToShow(tempCustomerFilters);

          if (tempCustomerFilters.length === customerFilters.length) {
            setCustomerSelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = customersToShow.filter((element) => {
          return element !== value;
        });

        setCustomersToShow(uncheckedValue);
        setCustomerSelectedAll(false);
      }
    }
    if (flag === "city") {
      if (checked) {
        const checkedValue = citiesToShow.includes(value);

        if (!checkedValue) {
          const tempCityFilters = [...citiesToShow, value];
          setCitiesToShow(tempCityFilters);

          if (tempCityFilters.length === cityFilters.length) {
            setCitySelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = citiesToShow.filter((element) => {
          return element !== value;
        });

        setCitiesToShow(uncheckedValue);
        setCitySelectedAll(false);
      }
    }
    if (flag === "region") {
      if (checked) {
        const checkedValue = regionsToShow.includes(value);

        if (!checkedValue) {
          const tempRegionFilters = [...regionsToShow, value];
          setRegionsToShow(tempRegionFilters);

          if (tempRegionFilters.length === regionFilters.length) {
            setRegionSelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = regionsToShow.filter((element) => {
          return element !== value;
        });

        setRegionsToShow(uncheckedValue);
        setRegionSelectedAll(false);
      }
    }
  };

  const applyfilters = () => {
    setAlertError("");
    setIsLoading(true);
    const getCustomerBarChartData = async () => {
      await axios({
        method: "post",
        url: "/api/on_shelf_availbility_customer",
        data: JSON.stringify({
          category: categoriesToShow,
          month: selectedMonth,
          year: selectedYear,
          city: citiesToShow,
          customer: customersToShow,
          region: regionsToShow,
        }),
      })
        .then((response) => {
          setIsLoading(false);
          setCustomerDataset(response?.data?.data);
          console.log(response, " new reposne");
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("Something went wrong. Please try again.");
        });
    };

    const getCategoryBarChartData = async () => {
      await axios({
        method: "post",
        url: "/api/on_shelf_availbility_category",
        data: JSON.stringify({
          category: categoriesToShow,
          month: selectedMonth,
          year: selectedYear,
          city: citiesToShow,
          customer: customersToShow,
          region: regionsToShow,
        }),
      })
        .then((response) => {
          setIsLoading(false);
          setCategoryDataset(response?.data?.data);
          console.log(response, " new reposne");
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("Something went wrong. Please try again.");
        });
    };

    const getCityBarChartData = async () => {
      await axios({
        method: "post",
        url: "/api/on_shelf_availbility_city",
        data: JSON.stringify({
          category: categoriesToShow,
          month: selectedMonth,
          year: selectedYear,
          city: citiesToShow,
          customer: customersToShow,
          region: regionsToShow,
        }),
      })
        .then((response) => {
          setIsLoading(false);
          setCityDataset(response?.data?.data);
          console.log(response, " new reposne");
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("Something went wrong. Please try again.");
        });
    };

    const getTableData = async () => {
      await axios({
        method: "post",
        url: "/api/on_shelf_availbility_customer_category",
        data: JSON.stringify({
          category: categoriesToShow,
          month: selectedMonth,
          year: selectedYear,
          city: citiesToShow,
          customer: customersToShow,
          region: regionsToShow,
        }),
      })
        .then((response) => {
          setTableData(response?.data);
          console.log(response, " new table");
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("Something went wrong. Please try again.");
        });
    };
    const getAvailabilityTotal = async () => {
      await axios({
        method: "post",
        url: "/api/on_shelf_availbility_total",
        data: JSON.stringify({
          category: categoriesToShow,
          city: citiesToShow,
          year: selectedYear,
          customer: customersToShow,
          region: regionsToShow,
        }),
      })
        .then((response) => {
          setIsLoading(false);
          setMonthDataset(response?.data?.data);
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAvailabilityPercentage(" ");
          setAlertError("Something went wrong. Please try again.");
        });
    };
    const getAvailabilityPercentage = async () => {
      await axios({
        method: "post",
        url: "/api/on_shelf_availbility_percent_total",
        data: JSON.stringify({
          category: categoriesToShow,
          month: selectedMonth,
          year: selectedYear,
          city: citiesToShow,
          customer: customersToShow,
          region: regionsToShow,
        }),
      })
        .then((response) => {
          setIsLoading(false);
          console.log(response, "month  total");
          if (response?.data?.data[0]?.result) {
            setAvailabilityPercentage(response?.data?.data[0]);
          } else {
            setAvailabilityPercentage("No Data found");
          }
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAvailabilityPercentage(" ");
          setAlertError("Something went wrong. Please try again.");
        });
    };
    const getMapData = async () => {
      await axios({
        method: "post",
        url: "/api/region_on_shelf_availbility_city",
        data: JSON.stringify({
          category: categoriesToShow,
          month: selectedMonth,
          year: selectedYear,
          city: citiesToShow,
          customer: customersToShow,
          region: regionsToShow,
        }),
      })
        .then((response) => {
          if (response?.request?.status === 200) {
            setRegionData(response?.data?.data);
            console.log(response, "regions");
          }
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("Something went wrong. Please try again.");
        });
    };
    getAvailabilityTotal();
    getAvailabilityPercentage();
    getCustomerBarChartData();
    getCategoryBarChartData();
    getCityBarChartData();
    getTableData();
    getMapData();
    fetchOsaLowestTableData();
    fetchOsaTableData();
    fetchOsaWaveData();
  };

  const emailImages = async (url, name) => {
    setButtonClicked(true);
    await axios({
      method: "post",
      url: url,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      data: {
        category: categoriesToShow,
        month: selectedMonth,
        year: selectedYear,
        city: citiesToShow,
        customer: customersToShow,
        username: props?.currentUser?.user,
        pagename: "OnShelfAvailability",
      },
      timeout: 300000,
    })
      .then((response) => {
        setButtonClicked(false);
        setAlertModal(response?.data?.data);
        timeOut();
      })
      .catch(function (error) {
        setButtonClicked(false);
        timeOut();
        console.log(error);
      });
  };
  const hideModal = () => {
    setAlertModal(null);
  };
  return (
    <div className="container-fluid monitor_performance position-relative">
      <div className="d-flex spinner_center">
      <div className="d-flex align-items-center">
          <h2 className="heading_sm_center">OnShelf Availability</h2>
          <button
            onClick={() => emailImages("/api/images_download", "Images")}
            className="btn btn-primary"
            style={{ width: "120px", height: "32px", marginLeft: "5px" }}
            disabled={!yearFilters}
          >
            {buttonClicked && !csvData ? (
              <div
                className="spinner-border text-info"
                role="status"
                style={{ zIndex: "2", width: "20px", height: "20px" }}
              ></div>
            ) : (
              "Extract Images"
            )}
          </button>
        </div>

        <div className="d-flex align-items-center justify-content-center">
          {isLoading && (
            <div className="text-center">
              <div
                className="spinner-border text-info"
                role="status"
                style={{ zIndex: "2" }}
              ></div>
              <br />
              <span className="text-info d-block">Loading...</span>
            </div>
          )}
        </div>
      </div>
      <div className="media-body">
        {alertModal && (
          <Modal show={alertModal ? true : false} onHide={hideModal} centered>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
              {alertModal}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={hideModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        {alertError && <Alert variant="danger">{alertError}</Alert>}
        {alertSuccess && <Alert variant="success">{alertSuccess}</Alert>}
        <div className="row content_main_row">
          <div className="filters col-md-12">
            <div className="row w-100 filters_row justify-content-between">
              <div className="col py-0">
                <div
                  className="filters_right w-100 justify-content-center"
                  style={{ gap: "5px" }}
                >
                  <div className="filter-dropdowns">
                    <Dropdown
                      className={`${
                        !selectedYear
                          ? "dropDown_disabled"
                          : "dropdown_selected"
                      }`}
                    >
                      <Dropdown.Toggle variant="secondary">
                        {selectedYear ? selectedYear : "Year"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {yearFilters?.map((year, index) => (
                          <Dropdown.Item
                            key={index}
                            onClick={(event) =>
                              setSelectedYear(event.target.text)
                            }
                          >
                            {year}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="filter-dropdowns">
                    <Dropdown
                      className={`${
                        !selectedMonth
                          ? "dropDown_disabled"
                          : "dropdown_selected"
                      }`}
                    >
                      <Dropdown.Toggle variant="secondary">
                        {selectedMonth ? selectedMonth : "Month"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {monthFilters?.map((month, index) => (
                          <Dropdown.Item
                            key={index}
                            onClick={(event) =>
                              setSelectedMonth(event.target.text)
                            }
                          >
                            {month}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <div className="col-md-9 col-xl-8">
                <div
                  className="filters_right w-100 justify-content-center"
                  style={{ gap: "5px" }}
                >
                  <div className="filter-dropdowns">
                    <Dropdown
                      className={`${
                        !(citiesToShow.length > 0)
                          ? "dropDown_disabled"
                          : "dropdown_selected"
                      }`}
                    >
                      <Dropdown.Toggle variant="secondary">
                        city
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div
                          className="p-3 d-flex flex-column"
                          style={{ gap: "5px" }}
                        >
                          <div className="d-flex" style={{ gap: "0 8px" }}>
                            <input
                              type="checkbox"
                              name="selectAll"
                              checked={citySelectedAll}
                              onChange={(e) => selectAll(e, "city")}
                            />
                            <label htmlFor="selectAll">Select All</label>
                          </div>

                          {cityFilters?.map((city, index) => (
                            <div
                              key={index}
                              className="d-flex"
                              style={{ gap: "0 10px" }}
                            >
                              <input
                                type="checkbox"
                                value={city}
                                onChange={(e) =>
                                  checkBoxFilterHandle(e, "city")
                                }
                                checked={
                                  citiesToShow.includes(city) ? true : false
                                }
                              />
                              <label style={{ fontSize: "12px" }}>{city}</label>
                            </div>
                          ))}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <div className="filter-dropdowns">
                    <Dropdown
                      className={`${
                        !(categoriesToShow.length > 0)
                          ? "dropDown_disabled"
                          : "dropdown_selected"
                      }`}
                    >
                      <Dropdown.Toggle variant="secondary">
                        category
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div
                          className="p-3 d-flex flex-column"
                          style={{ gap: "5px" }}
                        >
                          <div className="d-flex" style={{ gap: "0 8px" }}>
                            <input
                              type="checkbox"
                              name="selectAll"
                              checked={categorySelectedAll}
                              onChange={(e) => selectAll(e, "category")}
                            />
                            <label htmlFor="selectAll">Select All</label>
                          </div>

                          {categoryFilters?.map((category, index) => (
                            <div
                              key={index}
                              className="d-flex"
                              style={{ gap: "0 10px" }}
                            >
                              <input
                                type="checkbox"
                                value={category}
                                onChange={(e) =>
                                  checkBoxFilterHandle(e, "category")
                                }
                                checked={
                                  categoriesToShow.includes(category)
                                    ? true
                                    : false
                                }
                              />
                              <label style={{ fontSize: "12px" }}>
                                {category}
                              </label>
                            </div>
                          ))}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <div className="filter-dropdowns">
                    <Dropdown
                      className={`${
                        !(customersToShow.length > 0)
                          ? "dropDown_disabled"
                          : "dropdown_selected"
                      }`}
                    >
                      <Dropdown.Toggle variant="secondary">
                        customer
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div
                          className="p-3 d-flex flex-column"
                          style={{ gap: "5px" }}
                        >
                          <div className="d-flex" style={{ gap: "0 8px" }}>
                            <input
                              type="checkbox"
                              name="selectAll"
                              checked={customerSelectedAll}
                              onChange={(e) => selectAll(e, "customer")}
                            />
                            <label htmlFor="selectAll">Select All</label>
                          </div>

                          {customerFilters?.map((customer, index) => (
                            <div
                              key={index}
                              className="d-flex"
                              style={{ gap: "0 10px" }}
                            >
                              <input
                                type="checkbox"
                                value={customer}
                                onChange={(e) =>
                                  checkBoxFilterHandle(e, "customer")
                                }
                                checked={
                                  customersToShow.includes(customer)
                                    ? true
                                    : false
                                }
                              />
                              <label style={{ fontSize: "12px" }}>
                                {customer}
                              </label>
                            </div>
                          ))}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <div className="filter-dropdowns">
                    <Dropdown
                      className={`${
                        !(regionsToShow.length > 0)
                          ? "dropDown_disabled"
                          : "dropdown_selected"
                      }`}
                    >
                      <Dropdown.Toggle variant="secondary">
                        region
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div
                          className="p-3 d-flex flex-column"
                          style={{ gap: "5px" }}
                        >
                          <div className="d-flex" style={{ gap: "0 8px" }}>
                            <input
                              type="checkbox"
                              name="selectAll"
                              checked={regionSelectedAll}
                              onChange={(e) => selectAll(e, "region")}
                            />
                            <label htmlFor="selectAll">Select All</label>
                          </div>

                          {regionFilters?.map((region, index) => (
                            <div
                              key={index}
                              className="d-flex"
                              style={{ gap: "0 10px" }}
                            >
                              <input
                                type="checkbox"
                                value={region}
                                onChange={(e) =>
                                  checkBoxFilterHandle(e, "region")
                                }
                                checked={
                                  regionsToShow.includes(region) ? true : false
                                }
                              />
                              <label style={{ fontSize: "12px" }}>
                                {region}
                              </label>
                            </div>
                          ))}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <button className="btn btn-success" onClick={applyfilters}>
                    Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="row justify-content-center"
          style={{ marginTop: "5px" }}
        >
          <div className="col-12 d-flex justify-content-center">
            <div className="card">
              <div className="card-content">
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <div className="align-self-center d_sm_none">
                      <BsBookshelf className="icon" />
                    </div>
                    <div className="media-body text-right total_visits_con">
                      <span>OSA Percentage</span>
                      <h3 style={{ marginTop: "10px" }}>
                        {!availabilityPercentage?.result
                          ? "No Data Found"
                          : isNaN(availabilityPercentage?.result)
                          ? availabilityPercentage?.result
                          : availabilityPercentage?.result + "%"}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row gap-3 justify-content-center">
        <div className="col-md-5">
          <div className="row w-100 flex-column align-items-center">
            <div className="col-12 my_graph">
              <DoubleBarChart
                dataset={categoryDataset}
                label={"On-Shelf Availability By Category (%)"}
                loading={categoryDataset ? false : true}
              />
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <div className="row w-100 flex-column align-items-center">
            <div className="col-12 my_graph">
              <DoubleBarChart
                dataset={cityDataset}
                label={"On-Shelf Availability By City (%)"}
                loading={cityDataset ? false : true}
              />
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <div className="row w-100 flex-column align-items-center">
            <div className="col-12 my_graph">
              <DoubleBarChart
                dataset={customerDataset}
                label={"On-Shelf Availability By Customer (%)"}
                loading={customerDataset ? false : true}
              />
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <div className="row w-100 flex-column align-items-center">
            <div className="col-12 my_graph">
              <DoubleBarChart
                dataset={monthDataset}
                label={"On-Shelf Availability By Month (%)"}
                loading={monthDataset ? false : true}
              />
            </div>
          </div>
        </div>
      </div>
      <section className="card table table-responsive">
        <label htmlFor="" className="table_label mb-3">
          OSA By Category By Customer(%)
        </label>
        <Table bordered className="twoD_table">
          <thead>
            <tr>
              <th>Category</th>
              {tableData?.customer?.map((item) => (
                <th scope="col">{item}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData?.category?.map((cat, index) => (
              <tr className="table-primary">
                <th scope="row">{cat}</th>
                {tableData?.customer.map((cus) => (
                  <>
                    {tableData?.data?.map((item) => {
                      if (
                        item?.categoryShortName === cat &&
                        item?.StoreCustomerName === cus
                      ) {
                        return <td>{item?.result}</td>;
                      }
                    })}
                  </>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </section>
      {/* <div className="row justify-content-center"> */}
      {/* <div className="col p-0"> */}
      <section className="card">
        <label htmlFor="" className="table_label">
          Lowest OSA By Category (%)
        </label>
        {rows?.length > 0 && (
          <DynamicDataTable
            columnDetails={columnDetails}
            rowID={"ID"}
            columnHeaders={columnHeaders}
            rows={rows}
            isLoading={tableLoading}
            setPageSize={setPageSize}
            pageSize={pageSize}
            setPage={setPage}
            page={page}
            rowCount={rowCount}
          />
        )}
        {/* </div>
                </div> */}
      </section>
      <section className="card">
        <label htmlFor="" className="table_label">
          OSA By Wave (%)
        </label>
        {/* {waveRows && waveRows.length > 0 && (
                            <DataTable
                                isLoading={waveTableLoading}
                                columnDetails={waveColumnDetails}
                                columnHeaders={waveColumnHeaders}
                                rows={waveRows}
                                rowID="storeCode" />
                        )} */}
        {waveRows?.length > 0 && (
          <DynamicDataTable
            columnDetails={waveColumnDetails}
            // rowID={columnHeaders[]}

            columnHeaders={waveColumnHeaders}
            rows={waveRows}
            isLoading={waveTableLoading}
            setPageSize={setWavePageSize}
            pageSize={wavePageSize}
            setPage={setWavePage}
            page={wavePage}
            rowID={"Store Code"}
            rowCount={waveRowCount}
          />
        )}
      </section>

      <section className="card">
        <label htmlFor="" className="table_label">
          Lowest OSA By Store (%)
        </label>
        {lwcRows?.length > 0 && (
          <DynamicDataTable
            columnDetails={lwcColumnDetails}
            rowID={"ID"}
            columnHeaders={lwcColumnHeaders}
            rows={lwcRows}
            isLoading={lwcTableLoading}
            setPageSize={setLwcPageSize}
            pageSize={lwcPageSize}
            setPage={setLwcPage}
            page={lwcPage}
            rowCount={lwcRowCount}
          />
        )}
      </section>
      <CustomDataMap regionData={regionData} />
    </div>
  );
};

export default OnSehlfAvailability;
