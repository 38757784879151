import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import '../styles/components.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const StackBarChart = ({ dataForChar, colors, chartLabels, chartFor }) => 
{
    const [isLoading, setIsLoading] = useState(true);
    const [chartData, setChartData] = useState([]);

    const options = 
    {
        plugins: {   
            datalabels: {
                display: true,
                color: "#ffffff",
                align: "center",
                anchor: "center",
                font: { size: "8" }
            },
            legend: {
                labels: {
                    usePointStyle: true,
                    pointStyle: 'circle'
                }
            },
            title: {
                display: true,
                text: chartFor?chartFor:''
            }
        },
        scales: {
            x: {
                stacked: true
            },
            y: {
                stacked: true
            }
        }
    };

    useEffect(() => 
    {
        if (dataForChar) 
        {
            setChartData([]);

            for (let i = 0; i < Object.keys(dataForChar).length; i++) 
            {
                setChartData(chartData => [...chartData, 
                {
                    label: Object.entries(dataForChar)[i][0],
                    data: Object.entries(dataForChar)[i][1],
                    backgroundColor: "#" + colors[i]
                }]);
            }

            setIsLoading(false);
        }

    }, [dataForChar, colors])

    const labels = chartLabels ? chartLabels : [];

    const data = 
    {
        labels,
        datasets: chartData ? chartData : []
    };

    return (
        <div className = "position-ralative">
            {isLoading ? (
                <div className = "col-12 d-flex justify-content-center">
                    <br />
                    <div className = "spinner-border text-info" role = "status" style = {{ zIndex: '2' }}>
                    </div>
                </div>
            ) : (
                <Bar options = {options} data = {data} height = {200} width = {300} />
            )}
        </div>
    );
}

export default StackBarChart;